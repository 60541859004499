<template>
  <div class="modal" id="applyModal" tabindex="-1" aria-labelledby="applyModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="applyModalLabel">수강신청</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5>{{ data.title }}</h5>
          <table class="table table-sm mt-3">
            <thead>
            <tr class="table-light">
              <td><small>교육구분</small></td>
              <td><small>학습기간</small></td>
              <td><small>강의시간</small></td>
              <td><small>시수</small></td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{{ data.type == 1 ? '현장 교육' : '온라인 교육' }}</td>
              <td>{{ $getDate(data.startAt) }} ~ {{ $getDate(data.endAt) }}</td>
              <td>{{ data.length }}</td>
              <td>{{ data.credit }}</td>
            </tr>
            </tbody>
          </table>
          <h6>수강신청 정보</h6>
          <table class="table table-sm">
            <tbody>
            <tr>
              <td><small>이름</small></td>
              <td colspan="2"><input type="text" class="form-control form-control-sm bg-white" v-bind:value="getMyInfo().name" disabled /></td>
            </tr>
            <tr>
              <td><small>소속</small></td>
              <td><input type="text" class="form-control form-control-sm bg-white" v-bind:value="getMyInfo().area" disabled /></td>
              <td><input type="text" class="form-control form-control-sm bg-white" v-bind:value="getMyInfo().foundation" disabled /></td>
            </tr>
            <tr>
              <td><small>부서</small></td>
              <td colspan="2"><input type="text" class="form-control form-control-sm bg-white" v-bind:value="getMyInfo().department" disabled /></td>
            </tr>
            <tr>
              <td style="word-break: keep-all" width="10%"><small>핸드폰번호</small></td>
              <td colspan="2"><input type="text" class="form-control form-control-sm bg-white" v-bind:value="getMyInfo().phone" disabled /></td>
            </tr>
            <tr>
              <td><small>이메일</small></td>
              <td colspan="2"><input type="text" class="form-control form-control-sm bg-white" v-bind:value="getMyInfo().email" disabled /></td>
            </tr>
            <tr>
              <td><small>비고</small></td>
              <td colspan="2"><textarea class="form-control form-control-sm" v-model="remark" /></td>
            </tr>
            </tbody>
          </table>
          <h6>교육 약관 동의</h6>
          <div class="form-control form-control-sm" style="overflow-x: hidden; height: 100px;" v-html="classPrivacy"></div>
          <div class="form-check d-flex mb-2">
            <input class="form-check-input" type="checkbox" value="" id="check1" v-model="agreeClassPrivacy">
            <label class="form-check-label" for="check1">
              <small>개인정보의 수집,이용,제공에 관한 사항에 동의합니다.</small>
            </label>
          </div>
          <div class="form-control form-control-sm" style="overflow-x: hidden; height: 100px;" v-html="classNotice"></div>
          <div class="form-check d-flex">
            <input class="form-check-input" type="checkbox" value="" id="check2" v-model="agreeClassNotice">
            <label class="form-check-label" for="check2">
              <small>수강시 유의 사항에 동의합니다.</small>
            </label>
          </div>
        </div>
        <div class="modal-footer">
          <input type="button" class="btn btn-info" value="수강신청" @click="apply()"/>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LectureApply",
  props: {
    // lecture: String,
  },
  data() {
    return{
      data:{},
      openApply: false,
      remark:'',
      classPrivacy: '',
      classNotice: '',
      agreeClassPrivacy: false,
      agreeClassNotice: false,

    }
  },
  methods: {
    apply() {
      if (!(this.agreeClassPrivacy && this.agreeClassNotice)) {
        this.notify('warning', 'require_agree')
        return
      }
      if (this.remark.length > 50) {
        this.notify('warning', 'fail_write_article_content')
        return
      }
      let formData = new FormData()
      formData.append('lectureIdx', this.data.lectureIdx)
      formData.append('remark', this.remark)
      this.$post(this.$LECTURE_APPLY, formData, ()=>{
        this.notify('success', 'success_lecture_apply')
        this.closeApply()
        this.data = {}
        this.$root.$emit(this.$APPLY_CLOSE)
      }, ()=>{
        this.notify('danger', 'fail_lecture_apply')
      })

    },
    closeApply() {
      this.openApply = false
      window.$('#applyModal').modal('hide');
    },
    applyOpen(_lectureIdx) {
      this.remark = ''
      this.agreeClassNotice = false
      this.agreeClassPrivacy = false
      let formData = new FormData()
      formData.append('lectureIdx', _lectureIdx)
      this.$post(this.$LECTURE_DETAIL, formData, (result)=>{
        this.data = result['data'][0]
        this.openApply = true
        window.$('#applyModal').modal('show');
      }, ()=>{

      })
    }
  },
  mounted() {
    this.$root.$on(this.$APPLY_OPEN, (_lectureIdx)=>{
      this.applyOpen(_lectureIdx)
    })

    this.$get(this.$TERMS_CLASS_PRIVACY, (result)=>{
      this.classPrivacy = result['data'][0]
    },()=>{
    })
    this.$get(this.$TERMS_CLASS_NOTICE, (result)=>{
      this.classNotice = result['data'][0]
    },()=>{
    })
  },
  beforeDestroy() {
    this.$root.$off(this.$APPLY_OPEN)
  }
}
</script>

<style>
.apply-bg {
  position: absolute;
  background: #000000a0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.apply {
  position: absolute;
  left: 30%;
  top: 10%;
  background: white;
}
</style>
