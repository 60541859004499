<template>
  <div class="reply mt-3">
    <div class="count">
      <span>댓글 <strong>{{ total }}</strong></span>
    </div>
    <div class="body p-3" v-for="reply in data" v-bind:key="reply.replyIdx" v-bind:value="reply">
      <div class="d-flex">
        <div class="d-flex flex-column justify-content-center w-100 mr-3">
          <div>
            <span class="mr-2">{{ reply.maskedName }}</span>
            <small>{{ $getDateWithTime(reply.replyCreatedAt) }}</small>
          </div>
          <div>
            <span v-if="reply.isEditing != true">{{ reply.content }}</span>
            <div class="d-flex w-100" v-else>
              <input type="text" class="form-control w-100" placeholder="댓글을 등록하세요" v-model="reply.content" />
              <input type="button" class="ml-2 btn btn-success" value="댓글수정" @click="editReply(reply)" />
              <input type="button" class="ml-2 btn btn-danger" value="취소" @click="getReplyList()" />
            </div>
          </div>
        </div>
        <div class="dropdown ml-auto">
          <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-show="reply.mine && reply.isEditing != true">
            <i class="fas fa-ellipsis-v"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <input class="dropdown-item" type="button" value="수정" onclick="$('.dropdown-toggle').dropdown('hide')" @click="openEdit(reply)" />
            <input class="dropdown-item" type="button" value="삭제" onclick="$('.dropdown-toggle').dropdown('hide')" @click="deleteReply(reply)" />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-3">
      <textarea rows="4" class="form-control w-100" placeholder="댓글을 등록하세요" v-model="writeReplyContent"></textarea>
      <input type="button" class="ml-3 btn btn-warning" value="댓글등록" @click="writeReply()">
    </div>
  </div>
</template>

<script>
export default {
  name: "ClassReply",
  data() {
    return {
      isPending:false,
      data:[],
      total:0,
      writeReplyContent: ''
    }
  },
  methods: {
    getReplyList() {
      let formData = new FormData()
      formData.append('lectureIdx', this.$route.params.lectureIdx)
      formData.append('replyOffset', '0')
      this.$post(this.$MYCLASS_LECTURE_REPLY_LIST, formData, (result)=>{
        this.data = result['data']
        this.total = this.data.length
      }, ()=>{

      })
    },
    writeReply() {
      if (this.isPending) {
        return
      }
      if (this.writeReplyContent.length > 50) {
        this.notify('danger', 'fail_write_reply_length', true)
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('lectureIdx', this.$route.params.lectureIdx)
      formData.append('content', this.writeReplyContent)
      this.$post(this.$MYCLASS_LECTURE_REPLY_WRITE, formData, ()=>{
        this.isPending = false
        this.getReplyList()
        this.writeReplyContent = ''
        this.notify('success', 'success_write_reply')
      }, ()=>{
        this.isPending = false
        this.notify('danger', 'fail_write_reply')
      })
    },
    openMenu(_reply) {
      if (_reply.isOpenMenu == true) {
        _reply.isOpenMenu = false
      } else {
        _reply.isOpenMenu = true
      }
      this.$mount()
    },
    openEdit(_reply) {
      _reply.isOpenMenu = false
      _reply.isEditing = true
      this.$mount()
    },
    deleteReply(_reply) {
      _reply.isOpenMenu = false
      if (this.isPending) {
        return
      }
      if (confirm(this.trans('confirm_delete_reply')) != true) {
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('lectureIdx', this.$route.params.lectureIdx)
      formData.append('replyIdx', _reply.replyIdx)
      this.$post(this.$MYCLASS_LECTURE_REPLY_DELETE, formData, ()=>{
        this.isPending = false
        this.getReplyList()
        this.notify('success', 'success_delete_reply')
      }, ()=>{
        this.isPending = false
        this.notify('danger', 'fail_delete_reply')
      })
    },
    editReply(_reply) {
      if (this.isPending) {
        return
      }
      if (_reply.content.length > 50) {
        this.notify('danger', 'fail_write_reply_length', true)
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('lectureIdx', this.$route.params.lectureIdx)
      formData.append('content', _reply.content)
      formData.append('replyIdx', _reply.replyIdx)
      this.$post(this.$MYCLASS_LECTURE_REPLY_EDIT, formData, ()=>{
        this.isPending = false
        this.getReplyList()
        this.notify('success', 'success_edit_reply')
      }, ()=>{
        this.isPending = false
        this.notify('danger', 'fail_edit_reply')
      })
    }

  },
  mounted() {
    console.log('mounted')
    this.getReplyList()
  }
}
</script>

<style scoped>

</style>