<template>
<div>
  <MyClassHeader></MyClassHeader>
  <div class="row sub-layout mt-0 mt-sm-3">
    <div class="container">
      <div class="row">
        <div class="col-12 board-view">
          <div class="row">
            <div class="pt-3 col-12 col-sm-8">
              <div v-show="isLoading">
                <div id="loading" :style="'width: 100%; height: ' + videoHeight + 'px; background-color: #1b496a;'" class="text-center d-flex">
                  <div class="spinner-border text-primary m-auto" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <video-player v-show="!isLoading" :options="videoOptions" :configs="{ youtube: true }" @player-state-changed="playerStateChanged" ref="myPlayer">
              </video-player>
              <!--  <iframe width="560" height="315" src="https://www.youtube.com/embed/grC5n8gV0G8?autoplay=1" frameborder="0" allow="autoplay"></iframe>-->
            </div>
            <div class="col-12 col-sm-4">
              <div class="mb-auto"></div>
              <ChatReply :key="$route.params.lectureIdx" :videoHeight="videoHeight"></ChatReply>
            </div>
          </div>
          <div class="d-flex">
            <span class="mr-auto"><strong>{{ data.title }}</strong></span>
          </div>
          <div class="d-flex">
            <div class="d-none d-sm-block">
              <label>작성일</label>
              <span class="mr-3">{{ $getDate(data.createdAt) }}</span>
              <label>조회수</label>
              <span>{{ data.views }}</span>
            </div>
          </div>
          <div class="d-flex d-sm-none">
            <label>작성일</label>
            <span class="mr-auto">{{ $getDate(data.createdAt) }}</span>
            <label>조회수</label>
            <span>{{ data.views }}</span>
          </div>
          <div class="mt-3 pl-4">
            <label class="text-muted" style="width: 120px;">교육구분</label>
            <span class="mr-auto">온라인 교육</span><br/>
            <label class="text-muted" style="width: 120px;">교육운영기관</label>
            <span class="mr-3">{{ data.organization }}</span><br/>
            <label class="text-muted" style="width: 120px;">강사 정보</label>
            <span>{{ data.lecturer }}</span>
          </div>
          <div class="mt-3">
            <div class="text-break" v-html="data.description"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ChatReply from "@/components/myclass/ChatReply";
import MyClassHeader from "@/components/myclass/MyClassHeader";

export default {
  name: "LiveClassView",
  data () {
    return {
      data: {},
      videoOptions: {},
      videoHeight: 400,
      isLoading:true,
    }
  },
  methods: {
    playerStateChanged(playerCurrentState) {
      if (playerCurrentState.play) {
        // this.divShow = true
        console.log(playerCurrentState)

        setTimeout(() => {
          this.isLoading = false
        }, 3000)
        this.lectureUpdate()
      }
    },
    lectureUpdate() {
      let formData = new FormData()
      formData.append('lectureIdx', this.$route.params.lectureIdx)
      formData.append('progress', '0')
      this.$post(this.$MYCLASS_LECTURE_UPDATE, formData, ()=>{

      }, ()=>{

      })
    },
    getData() {
      let formData = new FormData()
      formData.append('lectureIdx', this.$route.params.lectureIdx)
      this.$post(this.$MYCLASS_LECTURE_DETAIL, formData, (result)=>{
        this.data = result['data'][0]
        this.videoOptions = {
          source: {
            type: "video/youtube",
            // src: "https://www.youtube.com/watch?v=Km3jPZFMk-Q"
            src: this.data['streamLink']
          },
          techOrder: ["youtube"],
          autoplay: true,
          controls: false,
          ytControls: false,
          height: this.videoHeight
        }
        this.isLoading = true
        setTimeout(() => {
          let player = this.player
          player.play()
        }, 3000)
      }, ()=>{

      })
    },
    goPrevDetail() {
      this.$router.push({name: 'ClassDocsView', params:{idx:this.data.prev}})
    },
    goNextDetail() {
      this.$router.push({name: 'ClassDocsView', params:{idx:this.data.next}})
    },
    goList() {
      this.$router.push({name:'InClassList'})
    }
  },
  computed: {
    player() {
      return this.$refs.myPlayer.player
    }
  },
  mounted() {
    let _vHeight = document.getElementsByClassName('col-12')[0].offsetWidth - 30
    console.log(_vHeight / 16 * 9)
    if (_vHeight / 16 * 9 < 400) {
      this.videoHeight = _vHeight / 16 * 9
    }
    this.getData()

  },
  components: {
    'ChatReply': ChatReply,
    'MyClassHeader': MyClassHeader,
  },
}
</script>

<style scoped>
.video-player { pointer-events: none;}
</style>
