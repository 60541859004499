<template>
  <div class=" mt-3" >
    <div :style="'height: ' + (videoHeight - 54) + 'px; overflow-y: scroll;'" id="replyList">
      <div class="body p-1" v-for="reply in data" v-bind:key="reply.replyIdx" v-bind:value="reply" >
        <div class="d-flex">
          <div v-if="!reply.mine" class="d-flex flex-column justify-content-center w-100 mr-3">
            <div>
              <span class="mr-2">{{ reply.maskedName }}</span>
              <small>{{ $getDateWithTime(reply.replyCreatedAt) }}</small>
            </div>
            <div class="m-1">
              <span class="p-1 bg-light border rounded">{{ reply.content }}</span>
            </div>
          </div>
          <div v-else class="d-flex flex-column justify-content-center w-100 ml-3">
            <div class="text-right">
              <small>{{ $getDateWithTime(reply.replyCreatedAt) }}</small>
            </div>
            <div class="text-right m-1">
              <span  class="p-1 border-info border rounded" >{{ reply.content }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-3">
      <textarea rows="1" class="form-control w-100" placeholder="내용을 입력하세요" v-model="writeReplyContent" style="resize: none;"></textarea>
      <input type="button" class="ml-3 btn btn-warning" value="전송" @click="writeReply()">
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatReply",
  props : {
    videoHeight : Number,
  },
  data() {
    return {
      isPending:false,
      data:[],
      writeReplyContent: '',
      reloadInterval:null
    }
  },
  methods: {
    getReplyList(isNew=true) {
      let formData = new FormData()
      formData.append('lectureIdx', this.$route.params.lectureIdx)
      if (!isNew) {
        formData.append('replyOffset', this.data[this.data.length - 1].replyIdx)
      } else {
        formData.append('replyOffset', '0')
      }
      this.$post(this.$MYCLASS_LECTURE_REPLY_LIST, formData, (result)=>{
        if (isNew) {
          this.data = result['data']
        } else {
          for (let i=0;i<result['data'].length;i++) {
            this.data.push(result['data'][i])
          }
        }

      }, ()=>{

      })
    },
    writeReply() {
      if (this.isPending) {
        return
      }
      if (this.writeReplyContent.length > 50) {
        this.notify('danger', 'fail_write_reply_length', true)
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('lectureIdx', this.$route.params.lectureIdx)
      formData.append('content', this.writeReplyContent)
      this.$post(this.$MYCLASS_LECTURE_REPLY_WRITE, formData, ()=>{
        this.isPending = false
        this.getReplyList()
        this.writeReplyContent = ''
      }, ()=>{
        this.isPending = false
        this.notify('danger', 'fail_write_reply')
      })
    },
    reloadReply() {
      this.reloadInterval = setInterval(function(){
        this.getReplyList(false)
      }.bind(this), 5000);
    }

  },
  mounted() {
    console.log('mounted')
    this.getReplyList()

    this.reloadReply()
  },
  updated() {
    let objDiv = document.getElementById("replyList");
    objDiv.scrollTop = objDiv.scrollHeight;
  },
  beforeDestroy() {
    clearInterval(this.reloadInterval)
  }
}
</script>

<style scoped>

</style>