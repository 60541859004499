<template>
  <div>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container col-12 col-md-6 mypage">
        <div class="row" v-show="!completed">
          <div class="col-12 d-flex">
            <h4 class="mt-3 mt-sm-0 ml-3 ml-md-0 mr-auto">전국지역문화재단연합회 계정 탈퇴 신청</h4>
          </div>
          <div class="col-12 board-view">
            <div class="border-bottom pb-3">
              <p class="pt-3"><span>탈퇴 전 꼭 확인해 주세요!</span></p>
              <p><span>전국지역문화재단연합회 계정은 전국지역문화재단연합회 전체 서비스 뿐 아니라 외부 서비스에서도 함께 이용되는 통합 계정입니다.</span></p>
              <p><span>계정을 탈퇴하면 계정 정보 및 서비스 이용 기록을 포함한 모든 정보가 삭제됩니다. 탈퇴한 후에는 더 이상 전국지역문화재단연합회 계정으로 로그인 할 수 없으므로, 본 계정으로 연결하여 사용한 외부 서비스들도 모두 이용할 수 없게 됩니다.</span></p>
              <p><span>* 계정을 탈퇴하시기 전 '계정이용'메뉴에서 현재 사용중인 서비스를 확인해 주세요. 탈퇴된 전국지역문화재단연합회 계정 정보와 서비스 이용기록 등은 복구할 수 없으니 신중하게 선택하시길 바랍니다.</span></p>
            </div>
            <div class="d-flex border-0 pt-3">
              <span class="w-25 pt-1">탈퇴 사유</span>
              <textarea class="form-control form-control-sm" style="height: 200px;" v-model="reason" />
            </div>
            <div class="d-flex border-0 pt-3">
              <span class="w-25 pt-1">비밀번호 확인</span>
              <input type="password" class="form-control" v-model="password">
            </div>
            <div class="input-group pl-2 pt-3">
              <input type="checkbox" class="mt-auto mb-auto mr-1" v-model="leaveAgree"/>
              <label class="form-check-label text-muted mr-auto">위 내용에 동의합니다.</label>
            </div>
            <div class="col-12 d-flex pt-3 pb-3 border-0">
              <input type="button" class="w-50 btn btn-secondary mr-2" value="탈퇴 취소" @click="leaveCancel()">
              <input type="button" class="w-50 btn btn-info" value="탈퇴" @click="leave()">
            </div>
          </div>
        </div>
        <div class="row" v-show="completed">
          <div class="col-12 text-center pb-3">
            <h4 class="mt-3 mt-sm-0 ml-3 ml-md-0 mr-auto text-secondary">회원탈퇴 신청이 <span class="text-info">완료되었습니다.</span></h4>
          </div>
          <div class="col-6 board-view m-auto"></div>
          <div class="col-12 text-center py-3">
            <p><strong>그동안 전국지역문화재단연합회 서비스를 이용해 주셔서 감사합니다.</strong></p>
            <p><span>보다 나은 서비스로 다시 찾아뵙겠습니다.</span></p>
          </div>
          <div class="col-6 m-auto pb-3">
            <input type="button" class="form-control btn btn-info clickable" value="메인화면" @click="goMain()">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Leave",
  data() {
    return {
      reason: '',
      password: '',
      leaveAgree: false,
      isPending: false,
      completed: false,
    }
  },
  methods: {
    leaveCancel() {
      this.$router.back()
    },
    leave() {
      if (this.isPending) {
        return
      }
      if (!this.leaveAgree) {
        this.notify('info', 'info_member_leave')
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('password', this.password)
      formData.append('reason', this.reason)
      this.$post(this.$MYPAGE_LEAVE, formData, () => {
        this.isPending = false
        this.completed = true
      }, (result) => {
        this.isPending = false
        this.notify('danger', result['message'], true)
      })
    },
    goMain() {
      window.dispatchEvent(new Event(this.$EVENT_LOGOUT))
    }
  }
}
</script>

<style scoped>

</style>
