<template>
  <div style="height: 40px;">
    <div style="float: left; width: 10%;" @click="goPage('Main')">로고</div>
    <div style="float: left; width: 70%;" >
      <span @click="goPage('LectureList')"> 역량강화교육 </span>
      <span @click="goPage('InClassList')"> 나의강의실 </span>
      <span @click="goPage('ForumList')"> 지역정책정보 </span>
      <span @click="goPage('Intro')">안내</span>
    </div>
    <div style="float: left; width: 10%;">
      <span @click="goPage('MainSearch')">검색</span></div>
    <div style="float: left; width: 10%;">
      <div v-show="!isLogin">
        <span @click="goPage('Login')">로그인</span> |
        <span @click="goPage('Terms')">회원가입</span>
      </div>
      <div v-show="isLogin">
        <span @click="logoutRequest()">로그아웃</span> |
        <span @click="goPage('MyPage')">마이페이지</span>
      </div>
    </div>
    <div v-if="isTest">
      <span @click="goPage('Main')">메인</span>,
      <span @click="goPage('ForumList')">지식공유포럼-목록</span>,
      <span @click="goPage('ForumView')">지식공유포럼-상세</span>,

      <span @click="goPage('PolicyList')">지역정책정보-목록</span>,
      <span @click="goPage('PolicyView')">지역정책정보-상세</span>,
      <span @click="goPage('PolicyWrite')">지역정책정보-쓰기</span>,

      <span @click="goPage('SuggestList')">제안-목록</span>,
      <span @click="goPage('SuggestView')">제안-상세</span>,
      <span @click="goPage('SuggestWrite')">제안-쓰기</span>,

      <span @click="goPage('Intro')">안내-인트로</span>,

      <span @click="goPage('NoticeList')">공지-목록</span>,
      <span @click="goPage('NoticeView')">공지-상세</span>,

      <span @click="goPage('InformationList')">이용안내</span>,

      <span @click="goPage('NewsList')">뉴스-목록</span>,
      <span @click="goPage('NewsView')">뉴스-상세</span>,

      <span @click="goPage('LectureList')">강의-목록</span>,
      <span @click="goPage('LectureView')">강의-상세</span>,
      <span @click="goPage('LectureApply')">강의-신청</span>,

      <span @click="goPage('InClassList')">내강의실-수강목록</span>,
      <span @click="goPage('InClassView')">내강의실-강의보기</span>,
      <span @click="goPage('LiveClassView')">내강의실-라이브강의보기</span>,
      <span @click="goPage('EndClassList')">내강의실-수강완료목록</span>,
      <span @click="goPage('ClassDocsList')">내강의실-자료실목록</span>,
      <span @click="goPage('ClassDocsView')">내강의실-자료실상세</span>,
      <span @click="goPage('ClassHistory')">내강의실-강의내역</span>,
  <!--    <span @click="goPage('ClassCertifyView')">내강의실-이수증출력</span>,-->

      <span @click="goPage('MainSearch')">검색</span>,
      <a href="#/user/login/foundation/fdsafdsafdsafdsafdsa">로그인-기관인증</a>,
      <div v-show="!isLogin">
        <span @click="goPage('Login')">로그인</span>,
        <span @click="goPage('FindId')">아이디찾기</span>,
        <span @click="goPage('FindPw')">비밀번호찾기</span>,

        <span @click="goPage('Terms')">회원가입-약관</span>,
        <span @click="goPage('MailAuth')">회원가입-메일인증</span>,
        <span @click="goPage('SignUp')">회원가입-정보입력</span>,
        <span @click="goPage('RegistComplet')">회원가입-가입완료</span>,
      </div>
      <div v-show="isLogin">
        <span @click="goPage('MyPage')">마이페이지-내정보</span>,
        <span @click="goPage('EditMyInfo')">마이페이지-내정보수정</span>,
        <span @click="goPage('EditPw')">마이페이지-비밀번호수정</span>,
        <span @click="goPage('Leave')">마이페이지-회원탈퇴</span>,

        <span @click="logoutRequest()">로그아웃</span>,
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "vue-header",
  data() {
    return {
      isLogin: false,
      isTest: false,
    }
  },
  methods: {
    goPage(page) {
      this.$router.push({name: page})
    },
    logoutRequest() {
      if (this.isPending) {
        return
      }
      this.notify('success', 'success_logout')
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        this.$post(this.$USER_LOGOUT, null, () => {
          this.logout()
        }, () =>{
          this.logout()
        })
      }

    },
    logout() {
      console.log('logout receive')
      this.isLogin = false
      localStorage.setItem('token','')
      this.storeFlush()
      this.goPage('Main')
    },
    handleLogIn() {
      this.isLogin = true
      this.$post(this.$MYPAGE, null, (result)=>{
        this.setMyInfo(result['data'][0])
      },(result)=>{
        this.notify('danger', result['message'])
      })
    },
    handleLogOut() {
      this.logout()
    },
  },
  mounted() {
    console.log('header mounted')
    let token = localStorage.getItem('token');
    if (!token || token.length == 0) {
      this.isLogin = false
    } else {
      this.isLogin = true
      this.$post(this.$MYPAGE, null, (result) => {
        this.setMyInfo(result['data'][0])
      }, () =>{
        this.logout()
      })
    }
    window.addEventListener(this.$EVENT_LOGIN, this.handleLogIn)
    window.addEventListener(this.$EVENT_LOGOUT, this.handleLogOut)
    // this.$root.$on(this.$EVENT_LOGIN, this.handleLogIn)
    // this.$root.$on(this.$EVENT_LOGOUT, this.handleLogOut)
  }
}

</script>

<style scoped>

</style>
