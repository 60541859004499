<template>
  <div class="row sub-menu mb-0">
    <div class="container">
      <div class="row d-flex">
        <a v-bind:class="{ active:($route.name.startsWith('Forum')) }" v-bind:href="goLink('ForumList')">지식공유포럼</a>
        <a v-bind:class="{ active:($route.name.startsWith('Policy')) }" v-bind:href="goLink('PolicyList')">지역정책정보 아카이브</a>
        <a v-bind:class="{ active:($route.name.startsWith('Suggest')) }" v-bind:href="goLink('SuggestList')">제안 및 논의</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PolicyHeader",
  methods: {
  },
}
</script>

<style scoped>

</style>