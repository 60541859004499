<template>
  <div v-show="openPrint" class="print-bg" style="z-index: 998; display: flex;">
    <div id="print" class="print" style="
  background-image: url(./images/certify.png);
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
  color-adjust: exact !important;                 /*Firefox*/
  z-index: 999;
">
      <!--      <img src="/image/certify.png" style="width: 600px; position: absolute;"/>-->
      <div class="d-flex mt-2" data-html2canvas-ignore="true" v-show="!printing" style="position: fixed;width: 100vw;max-width: 600px;">
        <input class="ml-auto mr-3 btn btn-info" type="button" @click="makePDF()" value="인쇄" />
        <input class="mr-auto btn btn-dark" type="button" @click="openPrint=false" value="취소"/>
      </div>
      <div>
        <p style="left: 26px; top: 78px;">제 {{ data.year }}호</p>
        <div style="position: relative; text-align: center; top:150px;">
          <p style="font-size: 20px; font-weight: bold;" class="mx-auto">교육 수료 증명서</p>
        </div>
        <p style="left: 100px; top: 200px;">소   속 : {{ data.foundationName }}</p>
        <p style="left: 100px; top: 200px;">성   명 : {{ data.name }}</p>
        <p style="left: 100px; top: 200px;">수 료 일 : {{ $getDate(data.completionDate) }}</p>
        <p style="left: 100px; top: 200px;">수료시수 : {{ data.credit }}시수</p>
        <div style="position: relative; text-align: center; top:250px; padding: 0 50px;">
          <p>상기인은 (사)전국지역문화재단연합회가 주관한 {{ year }} 지역문화재단 종사자 역량강화 교육 과정 중 '{{ data.lectureName }}'을(를) 수료하였기에 이 증서를 수여합니다.</p>
          <p style="font-size: 18px; margin-top: 35px;">{{ today }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf'
import html2canvas from'html2canvas'

export default {
  name: "ClassCertifyView",
  data() {
    return {
      openPrint:false,
      data:{},
      lectureIdx: '',
      today:'',
      printing:false,
      year: ''
    }
  },
  mounted() {
    this.$root.$on(this.$PRINT_OPEN, (_lectureIdx)=>{
      let formData = new FormData()
      formData.append('lectureIdx', _lectureIdx)
      this.$post(this.$MYCLASS_COMPLETION_DETAIL, formData, (result)=>{
        this.openPrint = true
        this.data = result['data'][0]
        this.lectureIdx = _lectureIdx
        this.openApply = true
        let date = new Date()
        this.today = this.$getDate1(date.getTime() / 1000)
        this.year = date.getFullYear()
      }, ()=>{

      })


      //test
      // console.log(_lectureIdx)
      // this.openPrint = true
      // this.data = {year:'2020',userIdx:'1',foundationName:'dfdf',name:'111',lectureName:'222',completionDate:'223333',credit:'10'}
      // this.openApply = true
      // this.lectureIdx = 1
      // this.today = this.$getDate(new Date().getTime() / 1000)


    })

  },
  beforeDestroy() {
    this.$root.$off(this.$PRINT_OPEN)
  },
  methods: {
    makePDF() {
      let pdf = new jsPDF('p', 'mm', 'a4')
      let canvas = pdf.canvas
      const pageWidth = 210
      // const pageHeight = 295
      canvas.width = pageWidth

      let ele = document.querySelector("#print")
      let width = ele.offsetWidth
      let height = ele.offsetHeight
      let imgHeight = pageWidth * height / width

      if (!ele) {
        return
      }

      html2canvas(ele).then(canvas => {
        let position = 0
        const imgData = canvas.toDataURL('image/png')
        pdf.addImage(imgData, 'png', 0, position, pageWidth, imgHeight, undefined, 'slow')
        // pdf.save('certify.pdf')
        pdf.autoPrint()
        pdf.output('dataurlnewwindow')
        this.printing = false
        this.openPrint = false
      });
    }
  },
}
</script>

<style>
.print-bg {
  position: absolute;
  background: #000000a0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: scroll;

}
.print {
  /*position: absolute;*/
  /*left: 5%;*/
  /*top: 0%;*/
  /*width: 600px;*/
  /*height: 847px;*/
  min-width: 600px;
  min-height: 847px;
  width: calc(600px * 1.0);
  height: calc(847px * 1.0);
  background: white;
  margin: auto;

}
.print p {
  position: relative;
}
</style>
