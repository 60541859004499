<template>
  <div>
    <GuideHeader></GuideHeader>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <div class="row">
          <div class="col-12 board-view">
            <div class="d-flex">
              <label>제목</label>
              <span class="mr-auto"><strong>{{ data.title }}</strong></span>
              <div class="d-none d-sm-block">
                <label>저작권자</label>
                <span>{{ data.licence }}</span>
              </div>
            </div>
            <div class="d-flex">
              <label>분류</label>
              <span class="mr-auto">{{ data.prefixName }}</span>
              <div class="d-none d-sm-block">
                <label>작성일</label>
                <span class="mr-3">{{ $getDate(data.articleCreatedAt) }}</span>
                <label>조회수</label>
                <span>{{ data.views }}</span>
              </div>
              <div class="d-sm-none">
                <label>저작권자</label>
                <span>{{ data.licence }}</span>
              </div>
            </div>
            <div class="d-flex d-sm-none">
              <label>작성일</label>
              <span class="mr-auto">{{ $getDate(data.articleCreatedAt) }}</span>
              <label>조회수</label>
              <span>{{ data.views }}</span>
            </div>
            <div class="d-flex" v-if="data.attachedFile && data.attachedFile.length > 0">
              <label>첨부파일</label>
              <span class="mr-auto">
                <div class="clickable inner-hover-text" v-for="file in data.attachedFile" v-bind:key="file.idx" @click="downloadFile(file)">
                  <i class="far fa-file"></i> {{ file.name }}<br/>
                </div>
              </span>
            </div>
            <div class="d-flex">
              <label>기사원문</label>
              <span class="mr-auto"><a :href="data.originUri" target="_blank">{{ data.originUri }}</a></span>
            </div>
            <div class="mt-3">
              <p v-if="data.contentImg && data.contentImg.link && data.contentImg.link.length > 0">
                <img v-bind:src="$DOMAIN + $BOARD_IMG + data.contentImg.link" />
              </p>
              <div class="text-break" v-html="data.content"></div>
            </div>
<!--            <BoardReply :key="$route.params.idx"></BoardReply>-->
            <div class="reply mt-3"></div>
            <div class="my-3">
              <div class="d-flex justify-content-end">
                <div class="btn-group">
                  <button type="button" class="btn btn-outline-dark" @click="goPrevDetail()" v-if="data.prev">
                    <i class="fas fa-arrow-left"></i>
                    이전
                  </button>
                  <button type="button" class="btn btn-outline-dark" @click="goNextDetail()" v-if="data.next">
                    다음
                    <i class="fas fa-arrow-right"></i>
                  </button>
                  <button type="button" class="btn btn-success" @click="goList()">
                    <i class="fas fa-list"></i>
                    목록
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuideHeader from "@/components/guide/GuideHeader";

export default {
  name: "NewsView",
  components: {
    'GuideHeader' : GuideHeader,
  },
  data() {
    return {
      data: {},
    }
  },
  methods: {
    downloadFile(_file) {
      if (!_file.permissions) {
        this.notify('warning', 'no_permission_download')
        return
      }
      console.log(_file)
      let formData = new FormData()
      formData.append('fileIdx', _file.idx)
      formData.append('hashPath', _file.link)
      this.$download(this.$BOARD_PROPOSAL_FILE_DOWNLOAD, formData, (result)=>{
        this.$fileDownload(result, _file.name)
      }, ()=>{
        this.notify('danger', 'fail_file_download')
      })
    },
    getData() {
      let formData = new FormData()
      formData.append('idx', this.$route.params.idx)
      formData.append('boardIdx', this.$route.params.boardIdx)
      let url = this.$BOARD_DETAIL
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        url = this.$BOARD_DETAIL_USER
      }
      this.$post(url, formData, (result)=>{
        this.data = result['data'][0]
      }, ()=>{

      })
    },
    goPrevDetail() {
      this.$router.push({name: 'NewsView', params:{idx:this.data.prev, boardIdx:this.$route.params.boardIdx}})
    },
    goNextDetail() {
      this.$router.push({name: 'NewsView', params:{idx:this.data.next, boardIdx:this.$route.params.boardIdx}})
    },
    goList() {
      this.$router.push({name:'NewsList'})
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    '$route.params.idx': function () {
      this.getData()
    }
  }
}
</script>

<style scoped>

</style>
