<template>
  <div>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>회원 가입을 환영합니다.</h4>
          </div>
          <div class="col-12 board-view pb-4">
            <div class="bg-light mb-3 mt-3 overflow-hidden">
              <div class="row">
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">01.<small> 약관동의</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">02.<small> 본인인증</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">03.<small> 정보입력</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-active">
                  <h4 class="m-3">04.<small> 가입완료</small></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-4 text-center">
            <h5 class="text-info">회원가입 완료</h5>
            <p>
              <span>회원가입이 완료되었습니다.</span><br/>
              <span>지역문화재단 종사자분들은 관리자 승인을 받으셔야</span><br/>
              <span>정상적인 서비스 이용이 가능합니다.</span><br/>
            </p>
          </div>
          <div class="col-12 col-md-5 col-lg-4 col-xl-3 m-auto pb-3">
            <input type="button" class="form-control btn btn-info clickable" value="로그인" @click="goLogin()">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistComplet",
  props: {
    email: {
      type: String,
    },
    username: {
      type: String,
    }
  },
  methods: {
    goLogin() {
      this.$router.push({name: 'Login'})
    }
  },
  mounted() {
    if (!this.$route.params.email || !this.$route.params.username) {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>
