<template>
  <div>
    <MyClassHeader></MyClassHeader>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <div class="row">
          <div class="col-12 board-view mb-3">
            <div class="pt-3" v-bind:class="isYoutube ? 'video-player-youtube' :''">
              <div v-show="isLoading">
                <div id="loading" :style="'width: 100%; height: ' + videoHeight + 'px; background-color: #1b496a;'" class="text-center d-flex">
                  <div class="spinner-border text-primary m-auto" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <video-player v-show="!isLoading" :options="videoOptions" :configs="{youtube : isYoutube}" @player-state-changed="playerStateChanged" ref="myPlayer">
              </video-player>
            </div>
            <div class="col-3 m-auto py-3 position-absolute px-0" :style="getStyle()">
              <input type="button" class="form-control btn btn-dark clickable" style="height: 70px; width: 105px;" value="이어보기" @click="showContinue()" v-show="!isLoading && data.progress > 0"/>
            </div>
            <div class="d-flex">
              <span class="mr-auto"><strong>{{ data.title }}</strong></span>
            </div>
            <div class="d-flex">
              <div class="d-none d-sm-block">
                <label>작성일</label>
                <span class="mr-3">{{ $getDate(data.createdAt) }}</span>
                <label>조회수</label>
                <span>{{ data.views }}</span>
              </div>
            </div>
            <div class="d-flex d-sm-none">
              <label>작성일</label>
              <span class="mr-auto">{{ $getDate(data.createdAt) }}</span>
              <label>조회수</label>
              <span>{{ data.views }}</span>
            </div>
            <div class="mt-3 pl-4">
              <label class="text-muted" style="width: 120px;">교육구분</label>
              <span class="mr-auto">온라인 교육</span><br/>
              <label class="text-muted" style="width: 120px;">교육운영기관</label>
              <span class="mr-3">{{ data.organization }}</span><br/>
              <label class="text-muted" style="width: 120px;">강사 정보</label>
              <span>{{ data.lecturer }}</span>
            </div>
            <div class="mt-3">
              <div class="text-break" v-html="data.description"></div>
            </div>
            <ClassReply :key="$route.params.lectureIdx"></ClassReply>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassReply from "@/components/myclass/ClassReply";
import MyClassHeader from "@/components/myclass/MyClassHeader";

export default {
  name: "InClassView",
  data () {
    return {
      currentTimeProgress: 0,
      videoOptions: {},
      data: {},
      videoHeight: 400,
      isLoading:true,
      isYoutube:false,
    }
  },methods: {
    playerStateChanged(playerCurrentState) {
      if (playerCurrentState.play) {
        if (this.data.progress > 0) {
          let player = this.player
          player.pause()
        }
        setTimeout(() => {
          this.isLoading = false
        }, 3000)
      }

      if (playerCurrentState.currentTime && this.player.duration() > 0) {
        let progress = Math.floor(playerCurrentState.currentTime / this.player.duration() * 100)
        if (progress > this.currentTimeProgress) {

          this.currentTimeProgress = progress

          this.lectureUpdate()
        }
      }
    },
    showContinue() {
      let player = this.player
      this.currentTimeProgress = this.data.progress
      let currentTime = Math.floor(this.player.duration() / 100 * this.data.progress)
      player.currentTime(currentTime)
      player.play()
      this.data.progress = 0
    },
    lectureUpdate() {
      let formData = new FormData()
      formData.append('lectureIdx', this.$route.params.lectureIdx)
      formData.append('progress', this.currentTimeProgress)
      this.$post(this.$MYCLASS_LECTURE_UPDATE, formData, ()=>{

      }, ()=>{

      })
    },
    getData() {
      let formData = new FormData()
      formData.append('lectureIdx', this.$route.params.lectureIdx)
      this.$post(this.$MYCLASS_LECTURE_DETAIL, formData, (result)=>{
        this.data = result['data'][0]
        console.log(this.data['streamLink'])
        if (this.data['streamLink'].startsWith("https://www.youtube.com") || this.data['streamLink'].startsWith("https://youtu.be")) {
          this.videoOptions = {
            source: {
              type: "video/youtube",
              // src: "https://www.youtube.com/watch?v=Km3jPZFMk-Q"
              src: this.data['streamLink']
            },
            techOrder: ["youtube"],
            autoplay: false,
            controls: false,
            ytControls: false,
            height: this.videoHeight
          }
          this.isLoading = true
          setTimeout(() => {
            let player = this.player
            player.play()
          }, 3000)
          this.isYoutube = true
        } else {
          this.videoOptions = {
            source: {
              type: "video/webm",
              src: this.data['streamLink'],
              // src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm',
              // if you need custom player state changed event name, you can config it like this
              customEventName: 'my-player-state-changed-event-custom-name'
            },
            height: this.videoHeight,
            playbackRates: [0.8, 1.0, 1.2],
            start: 0,
            autoplay: false,
            controlBar: {
              playToggle:true,
              progressControl: {
                seekBar: false
              }
            },
          }
          this.isLoading = false
          this.isYoutube = false
        }
      }, ()=>{

      })
    },
    goPrevDetail() {
      this.$router.push({name: 'ClassDocsView', params:{idx:this.data.prev}})
    },
    goNextDetail() {
      this.$router.push({name: 'ClassDocsView', params:{idx:this.data.next}})
    },
    goList() {
      this.$router.push({name:'InClassList'})
    },
    getStyle() {
      let height = this.videoHeight / 2 - 35
      return 'left: calc(50% - 52.5px); top: ' + height + 'px;'
    }
  },
  computed: {
    player() {
      return this.$refs.myPlayer.player
    }
  },
  mounted() {
    let _vHeight = document.getElementsByClassName('col-12')[0].offsetWidth - 30
    console.log(_vHeight)
    this.videoHeight = _vHeight / 16 * 9
    this.getData()

  },
  components: {
    'ClassReply': ClassReply,
    'MyClassHeader': MyClassHeader,
  },
  watch: {
    '$route.params.lectureIdx': function () {
      this.getData()
    }
  }
}
</script>

<style scoped>
.video-player-youtube { pointer-events: none;}
</style>
