import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)






/*route*/
import Main from '@/components/Main'
import MainSearch from '@/components/MainSearch'

import ForumList from "@/components/policyinfo/forum/ForumList";
import ForumView from "@/components/policyinfo/forum/ForumView";
import ForumWrite from "@/components/policyinfo/forum/ForumWrite";

import PolicyList from "@/components/policyinfo/policy/PolicyList";
import PolicyView from "@/components/policyinfo/policy/PolicyView";
import PolicyWrite from "@/components/policyinfo/policy/PolicyWrite";

import SuggestList from "@/components/policyinfo/suggest/SuggestList";
import SuggestView from "@/components/policyinfo/suggest/SuggestView";
import SuggestWrite from "@/components/policyinfo/suggest/SuggestWrite";

import Intro from "@/components/guide/intro/Intro";
import NoticeList from "@/components/guide/notice/NoticeList";
import NoticeView from "@/components/guide/notice/NoticeView";
import InformationList from "@/components/guide/information/InformationList";
import NewsList from "@/components/guide/news/NewsList";
import NewsView from "@/components/guide/news/NewsView";


import LectureList from "@/components/lecture/LectureList";
import LectureView from "@/components/lecture/LectureView";
// import LectureApply from "@/components/lecture/LectureApply";

import InClassList from "@/components/myclass/InClassList";
import InClassView from "@/components/myclass/InClassView";
import LiveClassView from "@/components/myclass/LiveClassView";
import ClassDocsList from "@/components/myclass/classdocs/ClassDocsList";
import ClassDocsView from "@/components/myclass/classdocs/ClassDocsView";
import ClassHistory from "@/components/myclass/classhistory/ClassHistory";
// import ClassCertifyView from "@/components/myclass/classhistory/ClassCertifyView";

import MyPage from "@/components/mypage/MyPage";
import EditMyInfo from "@/components/mypage/EditMyInfo";
import EditPw from "@/components/mypage/EditPw";
import Leave from "@/components/mypage/Leave";

import Login from "@/components/user/Login";
import LoginFoundation from "@/components/user/LoginFoundation";
import FindId from "@/components/user/FindId";
import FindPw from "@/components/user/FindPw";

import Terms from "@/components/user/signup/Terms";
import MailAuth from "@/components/user/signup/MailAuth";
import SignUp from "@/components/user/signup/SignUp";
import RegistComplet from "@/components/user/signup/RegistComplet";


export default new Router({
    routes: [
        //main
        {
            path: '/',
            name: 'Main',
            component: Main
        },
        {
            path: '/search',
            name: 'MainSearch',
            component: MainSearch
        },
        //forum
        {
            path: '/policyinfo/forum/list',
            name: 'ForumList',
            component: ForumList
        },
        {
            path: '/policyinfo/forum/view/:boardIdx/:idx',
            name: 'ForumView',
            component: ForumView
        },
        {
            path: '/policyinfo/forum/write',
            name: 'ForumWrite',
            component: ForumWrite
        },
        //policy
        {
            path: '/policyinfo/policy/list',
            name: 'PolicyList',
            component: PolicyList
        },
        {
            path: '/policyinfo/policy/view/:boardIdx/:idx',
            name: 'PolicyView',
            component: PolicyView
        },
        {
            path: '/policyinfo/policy/write',
            name: 'PolicyWrite',
            component: PolicyWrite
        },
        //suggest
        {
            path: '/policyinfo/suggest/list',
            name: 'SuggestList',
            component: SuggestList
        },
        {
            path: '/policyinfo/suggest/view/:boardIdx/:idx',
            name: 'SuggestView',
            component: SuggestView
        },
        {
            path: '/policyinfo/suggest/write',
            name: 'SuggestWrite',
            component: SuggestWrite
        },
        //intro
        {
            path: '/guide/intro',
            name: 'Intro',
            component: Intro
        },
        //information
        {
            path: '/guide/information/list',
            name: 'InformationList',
            component: InformationList
        },
        //notice
        {
            path: '/guide/notice/list',
            name: 'NoticeList',
            component: NoticeList
        },
        {
            path: '/guide/notice/view/:boardIdx/:idx',
            name: 'NoticeView',
            component: NoticeView
        },
        //news
        {
            path: '/guide/news/list',
            name: 'NewsList',
            component: NewsList
        },
        {
            path: '/guide/news/view/:boardIdx/:idx',
            name: 'NewsView',
            component: NewsView
        },
        //lecture
        {
            path: '/lecture/list',
            name: 'LectureList',
            component: LectureList
        },
        {
            path: '/lecture/view/:lectureIdx',
            name: 'LectureView',
            component: LectureView
        },
        // {
        //     path: '/lecture/apply',
        //     name: 'LectureApply',
        //     component: LectureApply
        // },
        //myclass
        {
            path: '/myclass/inclasslist',
            name: 'InClassList',
            component: InClassList
        },
        {
            path: '/myclass/inclassview/:lectureIdx',
            name: 'InClassView',
            component: InClassView
        },
        {
            path: '/myclass/liveclassview/:lectureIdx',
            name: 'LiveClassView',
            component: LiveClassView
        },
        {
            path: '/myclass/classdocslist',
            name: 'ClassDocsList',
            component: ClassDocsList
        },
        {
            path: '/myclass/classdocsview/:idx',
            name: 'ClassDocsView',
            component: ClassDocsView
        },
        {
            path: '/myclass/classhistory',
            name: 'ClassHistory',
            component: ClassHistory
        },
        // {
        //     path: '/myclass/classcertifyview',
        //     name: 'ClassCertifyView',
        //     component: ClassCertifyView
        // },
        //mypage
        {
            path: '/mypage',
            name: 'MyPage',
            component: MyPage
        },
        {
            path: '/mypage/editmyinfo',
            name: 'EditMyInfo',
            component: EditMyInfo
        },
        {
            path: '/mypage/editpw',
            name: 'EditPw',
            component: EditPw
        },
        {
            path: '/mypage/leave',
            name: 'Leave',
            component: Leave
        },

        //user
        {
            path: '/user/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/user/login/foundation/:verifyToken',
            name: 'LoginFoundation',
            component: LoginFoundation
        },
        {
            path: '/user/findid',
            name: 'FindId',
            component: FindId
        },
        {
            path: '/user/findpw',
            name: 'FindPw',
            component: FindPw
        },
        //signup
        {
            path: '/signup/terms',
            name: 'Terms',
            component: Terms
        },
        {
            path: '/signup/mailauth',
            name: 'MailAuth',
            component: MailAuth,
            props: true
        },
        {
            path: '/signup/inputinfo',
            name: 'SignUp',
            component: SignUp,
            props: true
        },
        {
            path: '/signup/complet',
            name: 'RegistComplet',
            component: RegistComplet,
            props: true
        }

    ],
    scrollBehavior () { return { x: 0, y: 0 } }
})
