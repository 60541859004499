<template>
  <div>
    <GuideHeader></GuideHeader>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div v-html="infoData"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuideHeader from "@/components/guide/GuideHeader";

export default {
  name: "Intro",
  components: {
    'GuideHeader' : GuideHeader,
  },
  data() {
    return {
      infoData:''
    }
  },
  mounted() {
    this.$get(this.$INFO_INFO, (result)=>{
      this.infoData = result['data'][0]
    }, ()=>{

    })
  }
}
</script>

<style scoped>

</style>
