<template>
  <div>
    <!-- 비주얼: -->
    <div class="row visual">
      <div id="carousel_pc" class="carousel slide w-100 d-none d-sm-block" data-ride="carousel" >
        <!-- 인디케이터: -->
        <ol class="carousel-indicators">
          <li data-target="#carousel_pc" v-for="(item, i) in visual['pc']" v-bind:key="item.idx" v-bind:data-slide-to="i" :class="{active : (i == 0)}"></li>
        </ol>
        <!-- :인디케이터 -->
        <div class="carousel-inner">
          <!-- 아이템: -->
          <div class="carousel-item clickable" v-for="(item, i) in visual['pc']" v-bind:key="item.idx" v-bind:class="{active : (i==0)}">
            <div class="item w-100" v-if="item.type == 0" v-bind:style="'background-image: url(' + $DOMAIN + $BOARD_IMG + item.hashPath + ');'" @click="openNewWindow(item.link)"></div>
            <div class="item w-100" v-else-if="item.type == 1" @click="openNewWindow(item.link)">
              <video class="item w-100 position-absolute" autoplay muted loop>
                <source :src="$DOMAIN + $BOARD_IMG + item.hashPath" type="video/mp4">
              </video>
            </div>
          </div>
          <!-- :아이템 -->
        </div>
        <!-- 화살표: -->
        <a class="carousel-control-prev" role="button" data-slide="prev" data-target="#carousel_pc">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" role="button" data-slide="next" data-target="#carousel_pc">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
        <!-- :화살표 -->
      </div>
      <div id="carousel_mobile" class="carousel slide w-100 d-sm-none" data-ride="carousel" >
        <!-- 인디케이터: -->
        <ol class="carousel-indicators">
          <li data-target="#carousel_mobile" v-for="(item, i) in visual['mobile']" v-bind:key="item.idx" v-bind:data-slide-to="i" :class="{active : (i == 0)}"></li>
        </ol>
        <!-- :인디케이터 -->
        <div class="carousel-inner">
          <!-- 아이템: -->
          <div class="carousel-item clickable" v-for="(item, i) in visual['mobile']" v-bind:key="item.idx" v-bind:class="{active : (i==0)}">
            <div class="item w-100" v-if="item.type == 0" v-bind:style="'background-image: url(' + $DOMAIN + $BOARD_IMG + item.hashPath + ');'" @click="openNewWindow(item.link)"></div>
            <div class="item w-100" v-else-if="item.type == 1" @click="openNewWindow(item.link)">
              <video class="item w-100 position-absolute" autoplay muted loop>
                <source :src="$DOMAIN + $BOARD_IMG + item.hashPath" type="video/mp4">
              </video>
            </div>
          </div>
          <!-- :아이템 -->
        </div>
        <!-- 화살표: -->
        <a class="carousel-control-prev" role="button" data-slide="prev" data-target="#carousel_mobile">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" role="button" data-slide="next" data-target="#carousel_mobile">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
        <!-- :화살표 -->
      </div>
    </div>
    <!-- :비주얼 -->
    <!-- 교육: -->
    <div class="row lecture">
      <div class="container-xl">
        <div class="row">
          <!-- 타이틀: -->
          <div class="col-12 text-center title">진행중 역량 강화 교육</div>
          <!-- :타이틀 -->
        </div>
        <div class="row">
          <div class="col-md-10 col-sm-12">
            <div class="row">
              <!-- 아이템: -->
              <div class="col-md-4 col-sm-12" v-for="item in inprogress.slice(0,3)" v-bind:key="item.lectureIdx">
                <div class="d-flex flex-column item" v-bind:style="'background-image: url(' + $DOMAIN + $BOARD_IMG + item.thumbnail + ');'">
                  <div>
                    <div class="float-left title">{{ item.type == 1 ? '현장 교육' : '온라인 교육' }}</div>
                  </div>
                  <div class="mt-auto d-flex flex-column content">
                    <!-- 내용: -->
                    <span class="h4 font-weight-bold clickable" @click="goPage('LectureView', {lectureIdx: item.lectureIdx})">{{ item.title }}</span>
                    <div class="hr"></div>
                    <span><label>신청기간</label> {{ $getDate(item.registerOpen) }} ~ {{ $getDateNoYear(item.registerClose) }}</span>
                    <span><label>인정시수</label> {{ item.credit }}:00</span>
                    <div>
                      <div class="float-right link" @click="goPage('LectureView', {lectureIdx: item.lectureIdx})">수강신청</div>
                    </div>
                    <!-- :내용 -->
                  </div>
                </div>
              </div>
              <!-- :아이템 -->
            </div>
          </div>
          <div class="col-md-2 col-sm-12">
            <div class="d-none d-md-flex more2 justify-content-center align-items-center text-center" @click="goPage('LectureList', null)">
              <span>더 많은<br>교육보기</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 more">
            <!-- 더보기: -->
            <div class="d-block d-md-none float-right" @click="goPage('LectureList', null)">
              <i class="far fa-plus-square"></i>
              더 많은 교육보기
            </div>
            <!-- :더보기 -->
          </div>
        </div>
      </div>
    </div>
    <!-- :교육 -->
    <!-- 지역: -->
    <div class="row area">
      <div class="container-xl">
        <div class="row">
          <!-- 타이틀: -->
          <div class="col-12 text-center title">지역정책정보</div>
          <!-- :타이틀 -->
        </div>
        <div class="row">
          <!-- 아이템: -->
          <div class="col-md-3 col-sm-12" v-for="(item, i) in areapolicy.slice(0,4)" v-bind:key="item.idx">
            <div class="d-flex flex-column item">
              <div class="icon">
                <div class="float-right">
                  <img v-bind:src="'/images/area_' + (i + 1) + '.png'" alt="">
                </div>
              </div>
              <div class="mt-auto d-flex flex-column content">
                <!-- 내용: -->
                <span v-if="item.boardIdx == 3" class="h2 font-weight-bold">지식공유포럼</span>
                <span v-else-if="item.boardIdx == 4" class="h2 font-weight-bold">지역정책정보</span>
                <div class="title">
                  <a class="clickable" @click="goPage(item.boardIdx == 3 ? 'ForumView' : 'PolicyView', {idx: item.idx, boardIdx:item.boardIdx})">{{ item.title }}</a>
                </div>
                <div class="d-flex flex-row">
                  <div class="d-flex flex-column info">
                    <span>{{ $getDate(item.articleCreatedAt) }}</span>
                    <span>조회 {{ item.views }}</span>
                  </div>
                  <div class="ml-auto text-center more" @click="goPage(item.boardIdx == 3 ? 'ForumView' : 'PolicyView', {idx: item.idx, boardIdx:item.boardIdx})">
                    <i class="fas fa-plus clickable" ></i>
                  </div>
                </div>
                <!-- :내용 -->
              </div>
            </div>
          </div>
          <!-- :아이템 -->
        </div>
      </div>
    </div>
    <!-- :지역 -->
    <!-- :게시판 -->
    <div class="row board">
      <div class="container-xl">
        <div class="row">
          <!-- 아이템: -->
          <div class="col-md-4 col-sm-12">
            <div class="row item">
              <div class="d-flex flex-column w-100">
                <div class="title">
                  <div class="float-left">공지사항</div>
                  <div class="float-right">
                    <a v-bind:href="goLink('NoticeList')" class="">+MORE</a>
                  </div>
                </div>
                <div class="d-flex flex-row content" v-for="(item, i) in notice.slice(0,3)" v-bind:key="item.idx">
                  <span>{{ i + 1 }}</span>
                  <a class="clickable" @click="goPage('NoticeView', {idx: item.idx, boardIdx:'6'})">{{ item.title }}</a>
                </div>
              </div>
            </div>
          </div>
          <!-- :아이템 -->
          <!-- 아이템: -->
          <div class="col-md-4 col-sm-12">
            <div class="row item">
              <div class="d-flex flex-column w-100">
                <div class="title">
                  <div class="float-left">뉴스룸</div>
                  <div class="float-right">
                    <a v-bind:href="goLink('NewsList')" class="">+MORE</a>
                  </div>
                </div>
                <div class="d-flex flex-row content" v-for="(item, i) in news.slice(0,3)" v-bind:key="item.idx">
                  <span>{{ i + 1 }}</span>
                  <a class="clickable" @click="goPage('NewsView', {idx: item.idx, boardIdx:'8'})">{{ item.title }}</a>
                </div>
              </div>
            </div>
          </div>
          <!-- :아이템 -->
          <!-- 아이템: -->
          <div class="col-md-4 col-sm-12 d-none d-md-block">
            <div class="row outside" style="background-image: url(/images/board_right.jpg);"></div>
            <div class="row inside d-flex flex-column">
              <div class="title">제안 및 논의</div>
              <div class="d-flex flex-column content">
                <div class="item" v-for="item in suggestion.slice(0,4)" v-bind:key="item.idx">
                  <span class="clickable">
<!--                    메인화면에서 제안 및 논의 상세글 클릭 시 인증회원만 열람 가능 - Nick, 20210227-->
                    <a @click="goPageWithRule(item.idx, '5')">{{ item.title }}</a>
                  </span>
                  <div class="info">
                    <div class="float-left">{{ $getDate(item.articleCreatedAt) }}</div>
                    <div class="float-right">조회 {{ item.views }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- :아이템 -->
        </div>
      </div>
    </div>
    <!-- :게시판 -->
    <!-- 팝업:-->
    <div class="d-none d-sm-block">
      <div class="popup_pc" v-for="popup in popup_pc" :key="popup.carouselIdx" v-show="popup.isShown">
        <img v-show="popup.type === 0" class="prev-area" :src="$DOMAIN + $BOARD_IMG + popup.hashPath" @click="openNewWindow(popup.link)">
        <div v-show="popup.type === 2" class="p-3 bg-white overflow-auto prev-area" style="" v-html="popup.plainText" @click="openNewWindow(popup.link)">
        </div>
        <div class="d-flex bg-light p-2 px-3">
          <input type="checkbox" class="mr-1 my-auto" @change="hideOndayPopup(popup)">
          <label class="form-check-label text-muted my-auto" >오늘 하루 열지 않기</label>
          <input type="button" value="닫기" class="ml-auto btn btn-secondary" @click="closePopup(popup)">
        </div>
      </div>
    </div>
    <div class="d-sm-none d-block">
      <div class="popup_mobile" v-for="popup in popup_mobile" :key="popup.carouselIdx" v-show="popup.isShown">
        <div class="w-100 h-100 d-flex" style="background-color: #000a;">
          <img v-show="popup.type === 0" class="m-auto prev-area" :src="$DOMAIN + $BOARD_IMG + popup.hashPath" @click="openNewWindow(popup.link)">
          <div v-show="popup.type === 2" class="p-3 bg-white overflow-auto prev-area m-auto" style="" v-html="popup.plainText" @click="openNewWindow(popup.link)">
          </div>
        </div>
        <div class="d-flex bg-light p-2 px-3 position-absolute w-100">
          <input type="checkbox" class="mr-1 my-auto" @change="hideOndayPopup(popup)">
          <label class="form-check-label text-muted my-auto" >오늘 하루 열지 않기</label>
          <input type="button" value="닫기" class="ml-auto btn btn-secondary" @click="closePopup(popup)">
        </div>
      </div>
    </div>
    <!-- :팝업 -->
  </div>
</template>

<script>
export default {
  name: "Main",
  data () {
    return {
      visual: [],
      important: [],
      inprogress: [],
      areapolicy: [],
      notice: [],
      news: [],
      suggestion: [],
      banner: [],
      popup_pc: [],
      popup_mobile: [],

    }
  },
  methods: {
    getMainData() {
      this.$get(this.$MAIN_ALL, (response)=>{
        this.visual = response['data']['visual'][0]
        this.important = response['data'].important
        this.inprogress = response['data'].inProgresses
        this.areapolicy = response['data'].areapolicy
        this.notice = response['data'].notice
        this.news = response['data'].news
        this.suggestion = response['data'].suggestion
        this.banner = response['data'].banner

      }, ()=>{

      })
    },
    loadCaroucel(_id) {
      console.log(_id + ' on load')
      window.$(_id).carousel({
        interval: 4000,
        pause: "hover",
        wrap: true,
        keyboard : false
      })

    },
    getPopup() {
      this.$get(this.$MAIN_POPUP, (response)=>{
        console.log(response['data'])
        this.popup_pc = []
        this.popup_mobile = []
        for (let i=0;i<response['data'][0]['pc'].length;i++) {
          let popup = response['data'][0]['pc'][i]
          let dd = new Date()
          let today = dd.getTime() / 1000
          let savedPopup = localStorage.getItem('popup_' + popup.carouselIdx)
          let ablePopup = (savedPopup === null || savedPopup === '' || today - savedPopup >= 86400)
          if (ablePopup) {
            popup.isShown = true
            this.popup_pc.push(popup)
          } else {
            popup.isShown = false
          }
        }
        for (let i=0;i<response['data'][0]['mobile'].length;i++) {
          let popup = response['data'][0]['mobile'][i]
          let dd = new Date()
          let today = dd.getTime() / 1000
          let savedPopup = localStorage.getItem('popup_' + popup.carouselIdx)
          let ablePopup = (savedPopup === null || savedPopup === '' || today - savedPopup >= 86400)
          if (ablePopup) {
            popup.isShown = true
            this.popup_mobile.push(popup)
          } else {
            popup.isShown = false
          }
        }

      }, ()=>{

      })
    },
    hideOndayPopup(_popup) {
      let dd = new Date()
      let today = dd.getTime() / 1000
      localStorage.setItem('popup_' + _popup.carouselIdx, today)
      this.closePopup(_popup)
    },
    closePopup(_popup) {
      _popup.isShown = false
      console.log(_popup)
    },

    // 메인화면에서 제안 및 논의 상세글 클릭 시 인증회원만 열람 가능 - Nick, 20210227
    goPageWithRule(_idx, _boardIdx) {
     let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        if (this.getMyInfo().userRule == 'USER' || this.getMyInfo().userRule == 'MEMBER') {
          this.notify('warning', 'require_fullmember')
          return
        }
        this.$router.push({name: 'SuggestView', params: {idx: _idx, boardIdx: _boardIdx}})
      } else {
        this.notify('warning', 'require_login')
      }
    }
  },
  mounted() {
    this.getMainData()
    this.getPopup()

    this.loadCaroucel('#carousel_pc')
    this.loadCaroucel('#carousel_mobile')
  },


};
</script>

<style>
.example-slide {
  align-items: center;
  color: #999;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  /*min-height: 100rem;*/
  height: 300px;
}
.popup_pc {
  position: absolute;
  top: 70px;
  left: 70px;
  z-index: 9999;
}
.popup_pc .prev-area {
  max-width: 350px;
  min-width: 250px;
  max-height: 500px;
}
.popup_mobile {
  position: absolute;
  top: 3px;
  left: 0px;
  width: 100%;
  height: calc(100% - 54px);
  margin: 0%;
  z-index: 9999;
}
.popup_mobile .prev-area {
  max-width: 350px;
  min-width: 200px;
  max-height: 500px;
}
</style>
