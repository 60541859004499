<template>
  <div>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <h4>내 정보 수정</h4>
        <div class="row board" v-show="checkBefore">
          <div class="col-12 pb-4">
            <h3 class="py-3">회원정보 확인</h3>
            <div class="card">
              <div class="card-body">
                <div class="input-group pb-1 ">
                  <span class="col-12 text-center text-muted pb-3">회원님의 정보를 안전하게 보호하기 위해 비밀번호를 다시 한번 입력해주세요.</span>
                </div>
                <div class="input-group pb-1">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1">비밀번호 확인</span>
                  <div class="col-9 d-flex">
                    <input type="password" class="form-control" v-model="password" @keyup.enter="checkPw()">
                  </div>
                </div>
                <div class="input-group pb-3"><span class="col-3 text-right my-auto pr-sm-5 pr-1"></span>
                  <div class="col-9">
                    <small class="text-danger">{{ errors['checkPassword'] }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-6 m-auto pb-3">
            <input type="button" class="form-control btn btn-info clickable" value="확인" @click="checkPw()">
          </div>
        </div>
        <div class="row board" v-show="!checkBefore">
          <div class="col-12 py-4">
            <div class="card">
              <div class="card-body">
                <div class="input-group pb-1">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1">이메일</span>
                  <div class="col-9 d-flex">
                    <input type="text" class="form-control" v-model="requestEmail" :disabled="disableEmail" @keyup.enter="requestCode()">
                    <input type="button" class="btn btn-info clickable ml-3" value="인증요청" @click="requestCode()">
                  </div>
                </div>
                <div class="input-group pb-3">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1"></span>
                  <div class="col-9 ">
                    <p><small class="text-danger">{{ errors['email'] }}</small></p>
                    <p v-show="successEmail"><small class="text-dark">인증 번호가 발송되었습니다. 메일에 포함된 인증번호를 확인해주세요.</small></p>
                  </div>
                </div>
                <div class="input-group pb-1">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1">인증코드</span>
                  <div class="col-9 d-flex">
                    <input type="text" class="form-control" v-model="authCode" @keyup.enter="confirmCode()">
                  </div>
                </div>
                <div class="input-group pb-3"><span class="col-3 text-right my-auto pr-sm-5 pr-1"></span>
                  <div class="col-9 ">
                    <p><small class="text-dark">이메일 변경을 위해 이메일 인증을 진행해 주세요.</small></p>
                    <p><small class="text-danger">{{ errorMessage }}</small></p>
                  </div>
                </div>
                <div class="input-group col-md-3 col-6 m-auto">
                  <input type="button" class="form-control btn btn-info clickable" value="이메일 변경" @click="confirmCode()">
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 py-4">
            <div class="card">
              <div class="card-body">
                <div>
                  <div class="input-group pb-1">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">아이디</span>
                    <div class="col-9 col-lg-4 d-flex">
                      <span>{{ username }}</span>
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-muted">5~20자 영문 소문자, 숫자 사용</small><br>
                      <small class="text-danger">{{ errors['username'] }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="input-group pb-1">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">이름</span>
                    <div class="col-9 col-lg-4 d-flex">
                      <input type="text" class=" form-control" v-model="userRealName" placeholder="이름">
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-danger">{{ errors['name'] }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="input-group pb-1">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">연락처</span>
                    <div class="col-9 col-lg-4 d-flex">
                      <input type="text" class=" form-control" v-model="phone" placeholder="연락처">
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-muted">연락처의 '-'를 제외한 숫자만 입력하세요.</small><br>
                      <small class="text-danger">{{ errors['phone'] }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="input-group pb-1">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">소속</span>
                    <div class="col-9 col-lg-4">
                      <select class="form-control" v-model="selectedArea" @change="selectArea()">
                        <option v-for="areaOption in areaOptions" v-bind:key="areaOption.value" v-bind:value="areaOption">
                          {{ areaOption.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-9 col-lg-4 ml-auto ml-lg-0 mt-1 mt-lg-0">
                      <select class="form-control" v-model="selectedFoundation" v-if="selectedArea.value != 999">
                        <option v-for="foundationOption in selectedArea.foundations" v-bind:key="foundationOption.idx" v-bind:value="foundationOption">
                          {{ foundationOption.name }}
                        </option>
                      </select>
                      <input type="text" class="form-control" v-else v-model="selectedEtc"/>
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-muted">권역 선택 후 재단 선택이 가능합니다.</small><br>
                      <small class="text-muted">일반회원의 경우 기타 항목 선택 후 직접 입력해주세요.</small><br>
                      <small class="text-danger">{{ errors['foundation'] }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="input-group pb-1">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1 pb-2 pb-lg-0">부서명</span>
                    <div class="col-9 col-lg-4 pb-2 pb-lg-0">
                      <input type="text" class="form-control" v-model="user_department" placeholder="부서명">
                    </div>
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">직위</span>
                    <div class="col-9 col-lg-4">
                      <input type="text" class="form-control" v-model="user_position" placeholder="직위">
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-danger">{{ errors['department'] }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="input-group pb-1">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">사무실번호</span>
                    <div class="col-9 col-lg-4 d-flex">
                      <input type="text" class=" form-control" v-model="user_officePhone" placeholder="사무실번호">
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-muted">연락처의 '-'를 제외한 숫자만 입력하세요.</small><br>
                      <small class="text-danger">{{ errors['officePhone'] }}</small>
                    </div>
                  </div>
                </div>
                <div class="input-group col-md-3 col-6 m-auto">
                  <input type="button" class="form-control btn btn-info clickable" value="확인" @click="editMyInfo()">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditMyInfo",
  data() {
    return {
      data:{},
      checkBefore: true,
      password:'',
      requestEmail: '',
      confirmEmail: '',
      authCode: '',
      isPending:false,
      disableEmail: false,
      username: '',
      userRealName: '',
      phone: '',
      areaOptions: [],
      selectedArea: {},
      selectedFoundation: {},
      selectedEtc: '',
      user_department: '',
      user_position: '',
      user_officePhone: '',
      errors:{
        'checkPassword':'',
        'email':'',
        'username':'',
        'name':'',
        'phone':'',
        'foundation':'',
        'department':'',
        'officePhone':'',
      },
      errorMessage: '',
      successEmail: false,
    }
  },
  methods: {
    checkPw() {
      if (this.isPending) {
        return
      }
      if (this.password == '') {
        this.errors['checkPassword'] = this.trans('require_password')
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('password', this.password)
      this.$post(this.$MYPAGE_CHECK_PASSWORD, formData, () => {
        this.isPending = false
        this.checkBefore = false
      }, () =>{
        this.isPending = false
        this.errors['checkPassword'] = this.trans('fail_password')
      })
    },
    requestCode() {
      if (this.isPending) {
        return
      }
      if (this.requestEmail == '') {
        this.errors['email'] = this.trans('require_input')
        return
      }
      if (!this.isEmail(this.requestEmail)) {
        this.errors['email'] = this.trans('fail_email_reg')
        return
      }
      this.isPending = true
      this.disableEmail = true
      let formData = new FormData()
      formData.append('email', this.requestEmail)
      this.$post(this.$MYPAGE_REQUEST_EMAIL, formData, () => {
        this.isPending = false
        this.confirmEmail = this.requestEmail
        this.successEmail = true
        this.notify('success', 'success_send_mail')
        this.errors['email'] = ''
      }, (result) =>{
        this.isPending = false
        this.disableEmail = false
        this.errors['email'] = result['message']
      })
    },
    confirmCode() {
      if (this.isPending) {
        return
      }
      if (this.authCode == '') {
        this.errorMessage = this.trans('require_auth_code')
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('email', this.confirmEmail)
      formData.append('code', this.authCode)
      this.$post(this.$MYPAGE_EDIT_EMAIL, formData, () => {
        this.isPending = false
        this.notify('success', 'success_info_change')
        this.$router.back()
      }, (result) => {
        this.isPending = false
        this.errorMessage = result['message']
      })
    },
    selectArea() {
      this.selectedEtc = ''
      if (this.selectedArea.value != 999) {
        this.selectedFoundation = this.selectedArea.foundations[0]
      } else {
        this.selectedFoundation = {}
      }
    },
    editMyInfo() {
      if (this.isPending) {
        return
      }
      if (this.userRealName == '') {
        this.errors['name'] = this.trans('require_input')
        return
      }
      if (this.phone == '') {
        this.errors['phone'] = this.trans('require_input')
        return
      }
      if (!this.isNumber(this.phone)) {
        this.errors['phone'] = this.trans('fail_num_reg')
        return
      }
      if (!this.isNumber(this.user_officePhone)) {
        this.errors['officePhone'] = this.trans('fail_num_reg')
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('name', this.userRealName)
      formData.append('phone', this.phone)
      formData.append('areaIdx', this.selectedArea.value)
      formData.append('foundation', (this.selectedArea.value != 999 ? this.selectedFoundation.idx : this.selectedEtc))
      formData.append('department', this.user_department)
      formData.append('position', this.user_position)
      formData.append('officePhone', this.user_officePhone)
      this.$post(this.$MYPAGE_EDIT, formData, () => {
        this.isPending = false
        this.notify('success', 'success_info_change')
        this.$router.push({name:'MyPage'})
      }, (result) => {
        this.isPending = false
        this.notify('danger', result['message'], true)
      })
    }
  },
  mounted() {
    this.$post(this.$MYPAGE, null, (result)=>{
      this.data = result['data'][0]
      this.setMyInfo(this.data)
      this.$get(this.$USER_FOUNDATION_INFO, (result)=>{
        this.areaOptions = result['data']
        for (const i in result['data']) {
          let area = result['data'][i]
          if (area['value'] == this.data.areaIdx) {
            this.selectedArea = area
            if (this.data.areaIdx == 999) {
              this.selectedFoundation = {}
              this.selectedEtc = this.data.foundation
            } else {
              for (const j in area['foundations']) {
                let foundation = area['foundations'][j]
                if (foundation.idx == this.data.foundationIdx) {
                  this.selectedFoundation = foundation
                  break
                }
              }
            }

            break
          }
        }
      },(result)=>{
        console.log(result['message'])
      })

      this.username = this.data.username
      this.userRealName = this.data.name
      this.phone = this.data.phone
      this.user_department = this.data.department
      this.user_position = this.data.position
      this.user_officePhone = this.data.officePhone
    },(result)=>{
      this.notify('danger', result['message'])
    })



  }
}
</script>

<style scoped>

</style>
