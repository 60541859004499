<template>
  <div>
    <GuideHeader></GuideHeader>
    <div class="row sub-layout mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="card bg-light mb-3">
              <div class="card-body">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button class="btn btn-outline-dark dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ ['제목', '내용', '제목+내용'][searchType] }}</button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#" @click="searchType=0">제목</a>
                      <a class="dropdown-item" href="#" @click="searchType=1">내용</a>
                      <a class="dropdown-item" href="#" @click="searchType=2">제목 + 내용</a>
                    </div>
                  </div>
                  <input type="text" class="form-control" aria-label="Text input with dropdown button" v-model="keyword" @keyup.enter="getList(0)">
                  <div class="input-group-append">
                    <button class="btn btn-success" type="button" id="button-addon2" @click="getList(0)">검색</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex align-items-center pb-3">
            <span class="mr-auto">총 {{ total }}건 ({{ page + 1 }}/{{ totalPage }})</span>
          </div>
        </div>
        <div class="row board-table">
          <div class="col-12">
            <table class="table table-hover">
              <thead>
              <tr class="d-none d-md-table-row">
                <th>번호</th>
                <th>분류</th>
                <th class="th-title">제목</th>
                <th>작성일</th>
                <th>조회수</th>
                <th>첨부</th>
                <th class="d-table-cell d-md-none"></th>
              </tr>
              </thead>
              <tbody>
              <tr class="clickable" v-for="item in data" v-bind:key="item.idx" v-bind:value="item" @click="goDetail(item.idx)">
                <td class="d-none d-md-table-cell">{{ item.idx }}</td>
                <td class="d-none d-md-table-cell">{{ item.prefixName }}</td>
                <td class="d-none d-md-table-cell">{{ item.title }}</td>
                <td class="d-none d-md-table-cell">{{ $getDateForList(item.articleCreatedAt) }}</td>
                <td class="d-none d-md-table-cell">{{ item.views }}</td>
                <td class="d-none d-md-table-cell"><i class="far fa-file" v-if="item.isFiles"></i></td>
                <td class="d-table-cell d-md-none">
                  <div class="d-flex">
                    <span class="mr-2">{{ item.idx }}</span>
                    <div class="d-flex flex-column w-100">
                      <div class="d-flex">
                        <span class="badge badge-secondary mr-2">{{ item.prefixName }}</span>
                        <span class="mr-2">{{ item.title }}</span>
                        <span><i class="far fa-file" v-if="item.isFiles"></i></span>
                      </div>
                      <small class="d-flex">
                        <span class="mr-2">{{ $getDateForList(item.articleCreatedAt) }}</span>
                        <span>조회 {{ item.views }}</span>
                      </small>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-show="data.length == 0" class="text-center py-5 my-5">
              <div class="text-muted mb-3">등록된 게시글이 없습니다.</div>
            </div>
            <div class="d-flex mb-3 menu">
              <ul class="pagination mr-auto">
                <li class="page-item" @click="goPrev()" v-bind:class="ablePrev?'':'disabled'"><input type="button" class="page-link" value="이전" /></li>
                <li class="page-item" v-for="paging in pages" v-bind:key="paging" @click="getList(paging)" v-bind:class="{ active:(paging == page) }"><input type="button" class="page-link" v-bind:value="paging + 1"/></li>
                <li class="page-item" @click="goNext()" v-bind:class="ableNext?'':'disabled'"><input type="button" class="page-link" value="다음"/></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuideHeader from "@/components/guide/GuideHeader";

export default {
  name: "NewsList",
  components: {
    'GuideHeader' : GuideHeader,
  },
  data() {
    return {
      boardIdx: '8', //test3 원래8
      searchRange: 0,
      searchType: 0,
      keyword: '',
      total: 0,
      totalPage : 0,
      data:[],
      page: 0,
      size: 10,
      sort: 'articleCreatedAt',
      direction: 'desc',
      sortDateStr: this.trans('latest')+'↓',
      ablePrev: false,
      ableNext: false,
      pages : [],
    }
  },
  methods: {
    getList(_page,_new=true) {
      this.page = _page
      let formData = new FormData()
      formData.append('boardIdx', this.boardIdx)
      formData.append('page', this.page)
      formData.append('size', this.size)
      formData.append('sort', this.sort)
      formData.append('direction', this.direction)
      formData.append('isPreview', '0') //미리보기off
      let url = this.$BOARD_LIST
      if (this.keyword != '') {
        url = this.$BOARD_SEARCH
        formData.append('keyword', this.keyword)
        formData.append('searchRange', this.searchRange)
        formData.append('searchType', this.searchType)
      }
      this.$post(url, formData, (result)=>{
        if (_new) {
          this.data = result['data']
        } else {
          for (let i=0;i < result['data'].length;i++) {
            this.data.push(result['data'][i])
          }
        }
        if (result['total'] > this.size * (this.page + 1)) {
          this.ableNext = true
        } else {
          this.ableNext = false
        }
        this.ablePrev = (this.page == 0 ? false : true)

        this.total = result['total'] ? result['total'] : 0
        this.totalPage = Math.floor(this.total % this.size == 0 ? this.total / this.size : this.total / this.size + 1)
        this.pages = []
        let startPage = (this.page - 5 > 0 ? this.page - 5 : 0)
        let endPage = (this.page + 5 < this.totalPage ? this.page + 5 : this.totalPage)
        for (let i=startPage;i<endPage;i++) {
          this.pages.push(i)
        }
      }, ()=>{

      })
    },
    sortByDate() {
      if (this.sort == 'articleCreatedAt') {
        if (this.direction == 'desc') {
          this.direction = 'asc'
          this.sortDateStr = this.trans('latest')+'↑'
        } else {
          this.direction = 'desc'
          this.sortDateStr = this.trans('latest')+'↓'
        }
      } else {
        this.sort = 'articleCreatedAt'
        this.direction = 'desc'
        this.sortDateStr = this.trans('latest')+'↓'
      }
      this.getList(0)
    },
    sortByTitle() {
      if (this.sort == 'title') {
        if (this.direction == 'desc') {
          this.direction = 'asc'
        } else {
          this.direction = 'desc'
        }
      } else {
        this.sort = 'title'
        this.direction = 'asc'
      }
      this.sortDateStr = this.trans('latest')+'↓'
      this.getList(0)
    },
    goPrev() {
      if (this.ablePrev) {
        this.getList(this.page - 1)
      }
    },
    goNext() {
      if (this.ableNext) {
        this.getList(this.page + 1)
      }
    },
    goDetail(_idx) {
      this.$router.push({name: 'NewsView', params: {idx: _idx, boardIdx:this.boardIdx}})
    },
  },
  mounted() {
    this.getList(0)
  },
}
</script>

<style scoped>

</style>
