<template>
  <div>
    <div>
      <div style="float: left; width: 30%;">로고 이미지</div>
      <div style="float: left; width: 70%;">
        <span>TEL : {{ data.tel }}</span> | <span>FAX : {{ data.fax }} ({{ data.hoursOfUse }})</span><br/>
        <span>{{ data.address }}, 우 : {{ data.zip }}</span>
      </div>
    </div>
    <div>
      <span>COPYRIGHT {{ data.copyright }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "vue-footer",
  data() {
    return {
      data: {},
    }
  },
  mounted() {
    this.$get(this.$FOOTER, (result)=>{
      this.data = result['data'][0]
    },()=>{
    })
  }
}
</script>

<style scoped>

</style>
