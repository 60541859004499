<template>
  <div>
    <div class="row sub-layout mt-3">
      <div class="container">
        <div class="row board">
          <div class="col-12 col-lg-6 pb-5 pt-5 m-auto">
            <div class="card">
              <div class="card-body">
                <h4><span>아이디 찾기</span><span class="text-black-50 font-weight-normal clickable" @click="findPw()"> | 비밀번호 찾기</span></h4>
                <div v-if="requestBefore">
                  <div class="input-group pt-3 pb-3">
                    <small>회원 정보에 등록한 이메일 주소와 이름이 일치해야 인증번호를 받을 수 있습니다.</small>
                  </div>
                  <div class="input-group pb-1">
                    <input type="text" class="form-control" v-model="email" placeholder="이메일">
                  </div>
                  <div class="input-group pb-1">
                    <input type="text" class="form-control" v-model="userRealName" placeholder="이름" @keyup.enter="findIdRequestEmail()">
                  </div>
                  <div class="input-group pb-3">
                    <small class="text-danger">{{ errorMessage }}</small>
                  </div>
                  <div class="input-group">
                    <input type="button" class="form-control btn btn-info clickable" value="확인" @click="findIdRequestEmail()">
                  </div>
                </div>
                <div v-else class="text-center text-muted">
                  <p class="pt-3"><span>회원님의 아이디 정보를</span><br/><span>회원가입시 등록한 이메일로 전송하였습니다.</span></p>
                  <p class="pb-3"><span>받은 메일함에 해당 메일이 없을 경우</span><br/><span>'스팸메일함'을 확인해주십시오.</span></p>
                  <div class="input-group">
                    <input type="button" class="form-control btn btn-info clickable" value="확인" @click="goLogIn()">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FindId",
  data() {
    return {
      isPending:false,
      email: '',
      userRealName: '',
      requestBefore: true,
      errorMessage: '',
    }
  },
  methods: {
    findPw() {
      this.$router.push({name:'FindPw'})
    },
    findIdRequestEmail() {
      if (this.isPending) {
        return
      }
      if (this.email == '' || this.userRealName == '') {
        this.errorMessage = this.trans('require_input')
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('name', this.userRealName)
      this.$post(this.$USER_FIND_ID, formData, () => {
        this.isPending = false
        this.requestBefore = false
      }, (result) =>{
        this.isPending = false
        this.errorMessage = result['message']
      })
    },
    goLogIn() {
      this.$router.push({name:'Login'})
    }
  },
}
</script>

<style scoped>

</style>
