import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VideoPlayer from 'vue-vjs-hls'
// import RtspPlayer from 'node-rtsp-stream'
import Header from './components/common/header/header'
import Footer from './components/common/footer/footer'

Vue.use(VideoPlayer)
// Vue.use(RtspPlayer)
Vue.config.productionTip = false


Vue.component('vue-header', Header)
Vue.component('vue-footer', Footer)


import Vuex from 'vuex'
Vue.use(Vuex)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import api from './commonjs/api';
Vue.use(api)

import event from './commonjs/event';
Vue.use(event)

import CustomDate from './commonjs/CustomDate';
Vue.use(CustomDate)

import common from "@/commonjs/common";
Vue.use(common)

import {store} from "./commonjs/store";

// import VueCarousel from '@chenfengyuan/vue-carousel';
// // Vue.use(VueCarousel);
// Vue.component('vue-carousel', VueCarousel);

new Vue({
  el: '#app',
  render: h => h(App),
  // render: createElement => createElement(App),
  router,
  VideoPlayer,
  store,
})
