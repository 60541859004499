export default Object.freeze  ({
    install(Vue) {
        Vue.prototype.$EVENT_LOGIN = 'EVENT_LOGIN'
        Vue.prototype.$EVENT_LOGOUT = 'EVENT_LOGOUT'

        Vue.prototype.$EVENT_EDITOR_ONLOAD = 'EVENT_EDITOR_ONLOAD'

        Vue.prototype.$APPLY_OPEN = 'APPLY_OPEN'
        Vue.prototype.$APPLY_CLOSE = 'APPLY_CLOSE'

        Vue.prototype.$PRINT_OPEN = 'PRINT_OPEN'
    }
})
