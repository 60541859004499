<template>
  <div>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <div class="row">
          <div class="pb-1 sub-menu bg-white input-group">
            <input type="text" class="form-control mr-3 border-0" placeholder="검색할 키워드를 입력 해 주세요." v-model="keyword" @keyup.enter="search()"/>
            <div class="input-group-append mr-2">
              <h4 class="fa fa-search m-auto clickable" @click="search()"></h4>
            </div>
          </div>
          <div class="col-12 board-view pb-4">
            <div class="mb-3 mt-3 overflow-hidden">
              <div class="font-weight-lighter pt-3">
                <div v-show="list['lecture'].length > 0">
                  <h5>역량강화 교육 <i class="fa fa-angle-right"/> 교육신청</h5>
                  <div class="d-flex border-bottom p-2" v-for="item in list['lecture']" v-bind:key="item.lectureIdx" @click="goLectureDetail(item.lectureIdx)">
                    <img v-if="item.image && item.image.length > 0" v-bind:src="$DOMAIN + $BOARD_IMG + item.image" class="search-img clickable" />
                    <div class="p-2 w-100">
                      <p class="d-flex"><strong class="clickable">{{ item.title }}</strong><small class="ml-auto">{{ $getDate(item.startAt) }}</small></p>
                      <span class="search-content clickable">{{ $stripHtml(item.description) }}</span>
                    </div>
                  </div>
                </div>
                <div class="border-bottom p-2" v-show="afterSearch && list['lecture'].length == 0">
                  <h5>역량강화 교육</h5>
                  <p class="ml-3">
                    <span>검색 결과가 없습니다.</span>
                  </p>
                </div>
                <div v-show="ableNext['lecture']">
                  <input type="button" class="btn btn-outline-warning mr-auto" v-show="ableNext" @click="getNextData1()" value="역량강화 교육 더보기"/>
                </div>
              </div>
              <div  class="font-weight-lighter">
                <div v-show="!(list['forum'].length == 0 && list['policy'].length == 0 && list['suggest'].length == 0)">
                  <div class="pt-5" v-show="list['forum'].length > 0">
                    <h5>지역정책정보 <i class="fa fa-angle-right"/> 지식공유포럼</h5>
                    <div class="d-flex border-bottom p-2" v-for="item in list['forum']" v-bind:key="item.idx" @click="goForumDetail(item.idx)">
                      <img v-if="item.thumbnail && item.thumbnail.length > 0" v-bind:src="$DOMAIN + $BOARD_IMG + item.thumbnail" class="search-img clickable" />
                      <div class="p-2 w-100">
                        <p class="d-flex"><strong class="clickable">{{ item.title }}</strong><small class="ml-auto">{{ $getDate(item.articleCreatedAt) }}</small></p>
                        <span class="search-content clickable">{{ $stripHtml(item.content) }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="pt-5" v-show="list['policy'].length > 0">
                    <h5>지역정책정보 <i class="fa fa-angle-right"/> 지역정책정보 아카이브</h5>
                    <div class="d-flex border-bottom p-2" v-for="item in list['policy']" v-bind:key="item.idx" @click="goPolicyDetail(item.idx)">
                      <img v-if="item.thumbnail && item.thumbnail.length > 0" v-bind:src="$DOMAIN + $BOARD_IMG + item.thumbnail" class="search-img clickable" />
                      <div class="p-2 w-100">
                        <p class="d-flex"><strong class="clickable">{{ item.title }}</strong><small class="ml-auto">{{ $getDate(item.articleCreatedAt) }}</small></p>
                        <span class="search-content clickable">{{ $stripHtml(item.content) }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="pt-5" v-show="list['suggest'].length > 0">
                    <h5>지역정책정보 <i class="fa fa-angle-right"/> 제안 및 논의</h5>
                    <div class="d-flex border-bottom p-2" v-for="item in list['suggest']" v-bind:key="item.idx" @click="goSuggestDetail(item.idx)">
                      <img v-if="item.thumbnail && item.thumbnail.length > 0" v-bind:src="$DOMAIN + $BOARD_IMG + item.thumbnail" class="search-img clickable" />
                      <div class="p-2 w-100">
                        <p class="d-flex"><strong class="clickable">{{ item.title }}</strong><small class="ml-auto">{{ $getDate(item.articleCreatedAt) }}</small></p>
                        <span class="search-content clickable">{{ $stripHtml(item.content) }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="pt-4" v-show="ableNext['forum'] || ableNext['policy'] || ableNext['suggest'] ">
                    <input type="button" class="btn btn-outline-warning mr-auto" v-show="ableNext" @click="getNextData2()" value="지역정책정보 더보기"/>
                  </div>
                </div>
                <div class="border-bottom p-2" v-show="afterSearch && list['forum'].length == 0 && list['policy'].length == 0 && list['suggest'].length == 0">
                  <h5>지역 정책 정보</h5>
                  <p class="ml-3">
                    <span>검색 결과가 없습니다.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div>

    </div>
  </div>
</template>

<script>
export default {
  name: "MainSearch",
  data() {
    return {
      afterSearch: false,
      keyword: '',
      boardIdx: {'forum':'3','policy':'4','suggest':'5'},
      page: {'lecture':0,'forum':0,'policy':0,'suggest':0},
      total: {'lecture':0,'forum':0,'policy':0,'suggest':0},
      size: 3,
      sortLecture: 'createdAt',
      sort: 'articleCreatedAt',
      direction: 'desc',
      searchRange: 0,
      searchType: 2,
      list: {'lecture':[],'forum':[],'policy':[],'suggest':[]},
      ableNext: {'lecture':false,'forum':false,'policy':false,'suggest':false},
    }
  },
  methods: {
    search() {
      this.getLectureList(0)
      this.getBoardList('forum',0)
      this.getBoardList('policy',0)
      this.getBoardList('suggest',0)
      this.afterSearch = true
    },
    getNextData1() {
      this.getLectureList(this.page['lecture'] + 1, false)
    },
    getNextData2() {
      if (this.ableNext['forum']) {
        this.getBoardList('forum',this.page['forum'] + 1, false)
      }
      if (this.ableNext['policy']) {
        this.getBoardList('policy',this.page['policy'] + 1, false)
      }
      if (this.ableNext['suggest']) {
        this.getBoardList('suggest',this.page['suggest'] + 1, false)
      }
    },
    getLectureList(_page,_new=true) {
      this.page['lecture'] = _page
      let formData = new FormData()
      formData.append('page', this.page['lecture'])
      formData.append('size', this.size)
      formData.append('sort', this.sortLecture)
      formData.append('direction', this.direction)
      formData.append('keyword', this.keyword)
      formData.append('searchRange', this.searchRange)
      formData.append('searchType', this.searchType)
      this.$post(this.$LECTURE_SEARCH, formData, (result)=>{
        if (_new) {
          this.list['lecture'] = result['data']
        } else {
          for (let i=0;i < result['data'].length;i++) {
            this.list['lecture'].push(result['data'][i])
          }
        }
        if (result['total'] > this.size * (this.page['lecture'] + 1)) {
          this.ableNext['lecture'] = true
        } else {
          this.ableNext['lecture'] = false
        }

        this.total['lecture'] = result['total']
      }, ()=>{

      })
    },
    getBoardList(_board,_page,_new=true) {
      this.page[_board] = _page
      let formData = new FormData()
      formData.append('boardIdx', this.boardIdx[_board])
      formData.append('page', this.page[_board])
      formData.append('size', this.size)
      formData.append('sort', this.sort)
      formData.append('direction', this.direction)
      formData.append('isPreview', '1') //미리보기on
      formData.append('keyword', this.keyword)
      formData.append('searchRange', this.searchRange)
      formData.append('searchType', this.searchType)
      this.$post(this.$BOARD_SEARCH, formData, (result)=>{
        if (_new) {
          this.list[_board] = result['data']
        } else {
          for (let i=0;i < result['data'].length;i++) {
            this.list[_board].push(result['data'][i])
          }
        }
        if (result['total'] > this.size * (this.page[_board] + 1)) {
          this.ableNext[_board] = true
        } else {
          this.ableNext[_board] = false
        }

        this.total[_board] = result['total']
      }, ()=>{

      })
    },
    goLectureDetail(_lectureIdx) {
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        this.$router.push({name: 'LectureView', params: {lectureIdx: _lectureIdx}})
      } else {
        this.notify('warning', 'require_login')
      }
    },
    goForumDetail(_idx) {
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        this.$router.push({name: 'ForumView', params: {idx: _idx, boardIdx:this.boardIdx['forum']}})
      } else {
        this.notify('warning', 'require_login')
      }
    },
    goPolicyDetail(_idx) {
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        this.$router.push({name: 'PolicyView', params: {idx: _idx, boardIdx:this.boardIdx['policy']}})
      } else {
        this.notify('warning', 'require_login')
      }
    },
    goSuggestDetail(_idx) {
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        this.$router.push({name: 'SuggestView', params: {idx: _idx, boardIdx:this.boardIdx['suggest']}})
      } else {
        this.notify('warning', 'require_login')
      }
    },
  }
}
</script>

<style>
</style>
