<template>
  <div>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>회원 가입을 환영합니다.</h4>
          </div>
          <div class="col-12 board-view pb-4">
            <div class="bg-light mb-3 mt-3 overflow-hidden">
              <div class="row">
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">01.<small> 약관동의</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">02.<small> 본인인증</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-active">
                  <h4 class="m-3">03.<small> 정보입력</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">04.<small> 가입완료</small></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-4">
            <h5>회원정보 입력</h5>
            <div class="card">
              <div class="card-body">
                <div>
                  <div class="input-group pb-1">
                    <strong class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">* 아이디</strong>
                    <div class="col-9 col-lg-4 d-flex">
                      <input type="text" class=" form-control" v-model="username" :disabled="disableUsername" placeholder="아이디" @keyup.enter="checkUsername()">
                      <input type="button" class="btn btn-info clickable ml-3" value="중복확인" @click="checkUsername()">
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-muted">5~20자 영문 소문자, 숫자 사용</small><br>
                      <small class="text-danger">{{ errors['username'] }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="input-group pb-1">
                    <strong class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1 pb-2 pb-lg-0">* 비밀번호</strong>
                    <div class="col-9 col-lg-4 pb-2 pb-lg-0">
                      <input type="password" class="form-control" v-model="password" placeholder="비밀번호">
                    </div>
                    <strong class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">* 비밀번호확인</strong>
                    <div class="col-9 col-lg-4">
                      <input type="password" class="form-control" v-model="re_password" placeholder="비밀번호확인">
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-muted">8~16자 영문 대소문자, 숫자, 특수문자</small><br>
                      <small class="text-danger">{{ errors['password'] }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="input-group pb-1">
                    <strong class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">* 이름</strong>
                    <div class="col-9 col-lg-4 d-flex">
                      <input type="text" class=" form-control" v-model="userRealName" placeholder="이름">
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-danger">{{ errors['name'] }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="input-group pb-1">
                    <strong class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">* 연락처</strong>
                    <div class="col-9 col-lg-4 d-flex">
                      <input type="text" class=" form-control" v-model="phone" placeholder="연락처">
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-muted">연락처의 '-'를 제외한 숫자만 입력하세요.</small><br>
                      <small class="text-danger">{{ errors['phone'] }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="input-group pb-1">
                    <strong class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">* 소속</strong>
                    <div class="col-9 col-lg-4">
                      <select class="form-control" v-model="selectedArea" @change="selectArea()">
                        <option v-for="areaOption in areaOptions" v-bind:key="areaOption.value" v-bind:value="areaOption">
                          {{ areaOption.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-9 col-lg-4 ml-auto ml-lg-0 mt-1 mt-lg-0">
                      <select class="form-control" v-model="selectedFoundation" v-if="selectedArea.value != 999">
                        <option v-for="foundationOption in selectedArea.foundations" v-bind:key="foundationOption.idx" v-bind:value="foundationOption">
                          {{ foundationOption.name }}
                        </option>
                      </select>
                      <input type="text" class="form-control" v-else v-model="selectedEtc"/>
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-muted">권역 선택 후 재단 선택이 가능합니다.</small><br>
                      <small class="text-muted">일반회원의 경우 기타 항목 선택 후 직접 입력해주세요.</small><br>
                      <small class="text-danger">{{ errors['foundation'] }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="input-group pb-1">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1 pb-2 pb-lg-0">부서명</span>
                    <div class="col-9 col-lg-4 pb-2 pb-lg-0">
                      <input type="text" class="form-control" v-model="user_department" placeholder="부서명">
                    </div>
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">직위</span>
                    <div class="col-9 col-lg-4">
                      <input type="text" class="form-control" v-model="user_position" placeholder="직위">
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-danger">{{ errors['department'] }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="input-group pb-1">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1">사무실번호</span>
                    <div class="col-9 col-lg-4 d-flex">
                      <input type="text" class=" form-control" v-model="user_officePhone" placeholder="사무실번호">
                    </div>
                  </div>
                  <div class="input-group pb-3">
                    <span class="col-3 col-lg-2 text-right my-auto pr-sm-5 pr-1"></span>
                    <div class="col-9 ">
                      <small class="text-muted">연락처의 '-'를 제외한 숫자만 입력하세요.</small><br>
                      <small class="text-danger">{{ errors['officePhone'] }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 col-lg-4 col-xl-3 m-auto pb-3">
            <input type="button" class="form-control btn btn-info clickable" value="회원가입" @click="signUp()">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUp",
  props: {
    email: {
      type: String,
    },
    mac: {
      type: String,
    }
  },
  data() {
    return {
      isPending:false,
      username: '',
      password: '',
      re_password: '',
      userRealName: '',
      phone: '',
      confirmUsername: '',
      confirmUsernameMac: '',
      disableUsername: false,
      areaOptions: [],
      selectedArea: {},
      selectedFoundation: {},
      selectedEtc: '',
      user_department: '',
      user_position: '',
      user_officePhone: '',
      errorMessage:'',
      errors:{
        'password':'',
        'email':'',
        'username':'',
        'name':'',
        'phone':'',
        'foundation':'',
        'department':'',
        'officePhone':'',
      },
    }
  },
  methods: {
    checkUsername() {
      if (this.isPending) {
        return
      }
      if (this.disableUsername) {
        return
      }
      if (this.username == '') {
        this.errors['username'] = this.trans('require_input')
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('username', this.username)
      formData.append('mac', this.$route.params.mac)
      this.$post(this.$USER_CHECK_ID, formData, (result) => {
        this.isPending = false
        if (result['data'][0].length > 0) {
          this.disableUsername = true
          this.confirmUsername = this.username
          this.confirmUsernameMac = result['data'][0]
          this.errors['username'] = ''
          this.notify('success', 'success_use', true)
        } else {
          this.errors['username'] = this.trans('info_retry')
        }
      }, (result) =>{
        this.isPending = false
        this.errors['username'] = result['message']
      })
    },
    selectArea() {
      this.selectedEtc = ''
      if (this.selectedArea.value != 999) {
        this.selectedFoundation = this.selectedArea.foundations[0]
      } else {
        this.selectedFoundation = {}
      }
    },
    signUp() {
      if (this.isPending) {
        return
      }
      if (this.confirmUsernameMac.length == 0) {
        this.notify('warning', 'require_id_unique', true)
        return
      }
      if (this.username == '') {
        this.errors['username'] = this.trans('require_input')
        return
      }
      if (this.password == '' || this.re_password == '') {
        this.errors['password'] = this.trans('require_input')
        return
      }
      if (this.userRealName == '') {
        this.errors['name'] = this.trans('require_input')
        return
      }
      if (this.phone == '') {
        this.errors['phone'] = this.trans('require_input')
        return
      }
      if (!this.isNumber(this.phone)) {
        this.errors['phone'] = this.trans('fail_num_reg')
        return
      }
      if (!this.isNumber(this.user_officePhone)) {
        this.errors['officePhone'] = this.trans('fail_num_reg')
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('username', this.username)
      formData.append('password', this.password)
      formData.append('passwordConfirm', this.re_password)
      formData.append('email', this.$route.params.email)
      formData.append('name', this.userRealName)
      formData.append('phone', this.phone)
      formData.append('areaIdx', this.selectedArea.value)
      formData.append('foundation', (this.selectedArea.value != 999 ? this.selectedFoundation.idx : this.selectedEtc))
      formData.append('department', this.user_department)
      formData.append('position', this.user_position)
      formData.append('officePhone', this.user_officePhone)
      formData.append('mac', this.confirmUsernameMac)
      this.$post(this.$USER_REGISTER, formData, () => {
        this.isPending = false
        this.$router.push({name: 'RegistComplet', params: {username: this.username, email: this.$route.params.email}})
      }, (result) =>{
        this.isPending = false
        for (let i=0;i<result.error.length;i++) {
          let err = result.error[i]
          this.errors[err.field] = err.message
        }
        this.notify('danger', result['message'], true)
      })
    }
  },
  mounted() {
    if (!this.$route.params.email || !this.$route.params.mac) {
      this.$router.back()
    }
    this.$get(this.$USER_FOUNDATION_INFO, (result)=>{
      this.areaOptions = result['data']
      this.selectedArea = result['data'][0]
      this.selectedFoundation = this.selectedArea.foundations[0]
    },(result)=>{
      console.log(result['message'])
    })
  }
}
</script>

<style scoped>

</style>
