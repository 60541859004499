<template>
  <div>
    <MyClassHeader></MyClassHeader>
    <div class="row sub-layout mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex align-items-center pb-3">
            <span class="mr-auto">총 {{ total }}건 ({{ page + 1 }}/{{ totalPage }})</span>
          </div>
        </div>
        <div class="row board-table">
          <div class="col-12">
            <table class="table table-hover">
              <thead>
              <tr class="d-none d-md-table-row">
                <th>번호</th>
                <th class="th-title">교육명</th>
                <th class="th-l">교육구분</th>
                <th>시수</th>
                <th>이수증</th>
                <th class="d-table-cell d-md-none"></th>
              </tr>
              </thead>
              <tbody>
              <tr class="clickable" v-for="item in data" v-bind:key="item.lectureIdx" v-bind:value="item" @click="goPrint(item.lectureIdx)">
                <td class="d-none d-md-table-cell">{{ item.lectureIdx }}</td>
                <td class="d-none d-md-table-cell">{{ item.title }}</td>
                <td class="d-none d-md-table-cell">{{ item.type == 1 ? '현장 교육' : '온라인 교육' }}</td>
                <td class="d-none d-md-table-cell">{{ item.credit }}</td>
                <td class="d-none d-md-table-cell"><i class="far fa-file" @click="goPrint(item.lectureIdx)"></i></td>
                <td class="d-table-cell d-md-none">
                  <div class="d-flex">
                    <span class="mr-2">{{ item.lectureIdx }}</span>
                    <div class="d-flex flex-column w-100">
                      <div class="d-flex">
                        <span class="badge badge-secondary mr-2">{{ item.type == 1 ? '현장 교육' : '온라인 교육' }}</span>
                        <span class="mr-2">{{ item.title }}</span>
                        <span><i class="far fa-file"></i></span>
                      </div>
                      <small class="d-flex">
                        <span>시수 {{ item.credit }}</span>
                      </small>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-show="data.length == 0" class="text-center py-5 my-5">
              <div class="text-muted mb-3">이수 완료한 교육이 없습니다.</div>
            </div>
            <div class="d-flex mb-3 menu">
              <ul class="pagination mr-auto">
                <li class="page-item" @click="goPrev()" v-bind:class="ablePrev?'':'disabled'"><input type="button" class="page-link" value="이전" /></li>
                <li class="page-item" v-for="paging in pages" v-bind:key="paging" @click="getList(paging)" v-bind:class="{ active:(paging == page) }"><input type="button" class="page-link" v-bind:value="paging + 1"/></li>
                <li class="page-item" @click="goNext()" v-bind:class="ableNext?'':'disabled'"><input type="button" class="page-link" value="다음"/></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ClassCertifyView></ClassCertifyView>
  </div>
</template>

<script>
import MyClassHeader from "@/components/myclass/MyClassHeader";
import ClassCertifyView from "@/components/myclass/classhistory/ClassCertifyView";

export default {
  name: "ClassHistory",
  components: {
    'MyClassHeader': MyClassHeader,
    'ClassCertifyView': ClassCertifyView,
  },
  data() {
    return {
      total: 0,
      totalPage : 0,
      data:[],
      page: 0,
      size: 10,
      sort: 'registerCreatedAt',
      direction: 'desc',
      ablePrev: false,
      ableNext: false,
      pages : [],
    }
  },
  methods: {
    getList(_page,_new=true) {
      this.page = _page
      let formData = new FormData()
      formData.append('page', this.page)
      formData.append('size', this.size)
      formData.append('sort', this.sort)
      formData.append('direction', this.direction)
      this.$post(this.$MYCLASS_COMPLETION_LIST, formData, (result)=>{
        if (_new) {
          this.data = result['data']
        } else {
          for (let i=0;i < result['data'].length;i++) {
            this.data.push(result['data'][i])
          }
        }
        if (result['total'] > this.size * (this.page + 1)) {
          this.ableNext = true
        } else {
          this.ableNext = false
        }
        this.ablePrev = (this.page == 0 ? false : true)

        this.total = result['total'] ? result['total'] : 0
        this.totalPage = Math.floor(this.total % this.size == 0 ? this.total / this.size : this.total / this.size + 1)
        this.pages = []
        let startPage = (this.page - 5 > 0 ? this.page - 5 : 0)
        let endPage = (this.page + 5 < this.totalPage ? this.page + 5 : this.totalPage)
        for (let i=startPage;i<endPage;i++) {
          this.pages.push(i)
        }
      }, ()=>{

      })
    },
    goPrev() {
      if (this.ablePrev) {
        this.getList(this.page - 1)
      }
    },
    goNext() {
      if (this.ableNext) {
        this.getList(this.page + 1)
      }
    },
    goPrint(_lectureIdx) {
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        let myinfo = JSON.parse(localStorage.getItem('myinfo')) || ''
        let userIdx = myinfo.idx
        window.open("https://k-cas.kr/cert.php?userIdx=" + userIdx + "&_lectureIdx=" + _lectureIdx, "_blank")
        //this.$root.$emit(this.$PRINT_OPEN, _lectureIdx)
      } else {
        this.notify('warning', 'require_login')
      }
    }
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token || token.length == 0) {
      this.notify('warning', 'require_login')
      this.$router.back()
      return
    }
    this.getList(0)
  },
}
</script>

<style scoped>

</style>
