<template>
  <div>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container col-12 col-md-6 mypage">
        <div class="row">
          <div class="col-12 d-flex">
            <h4 class="mt-3 mt-sm-0 ml-3 ml-md-0 mr-auto">내 정보 관리</h4>
            <input type="button" class="btn btn-outline-success m-1" @click="goPage('EditMyInfo')" value="정보 수정"/>
          </div>
          <div class="col-12 board-view">
            <h5 class="pt-3">개인정보</h5>
            <div class="d-flex">
              <span class="mr-auto">아이디</span>
              <span class="font-weight-light">{{ data.username }}</span>
            </div>
            <div class="d-flex">
              <span class="mr-auto">비밀번호</span>
              <div class="text-info clickable my-auto pr-2 font-weight-light" @click="goPage('EditPw')">
                <span>비밀번호 변경하기</span>
                <i class="fa fa-angle-right"/>
              </div>
            </div>
            <div class="d-flex">
              <span class="mr-auto">이름</span>
              <span class="font-weight-light">{{ data.name }}</span>
            </div>
            <div class="d-flex">
              <span class="mr-auto">연락처</span>
              <span class="font-weight-light">{{ data.phone }}</span>
            </div>
            <div class="d-flex">
              <span class="mr-auto">이메일</span>
              <span class="font-weight-light">{{ data.email }}</span>
            </div>
            <h5 class="pt-4">소속</h5>
            <div class="d-flex">
              <span class="mr-auto">권역</span>
              <span class="font-weight-light">{{ data.area }}</span>
            </div>
            <div class="d-flex">
              <span class="mr-auto">재단</span>
              <i v-if="data.userRule == 'FULLMEMBER'" class="fa fa-check-circle my-auto text-info">인증</i>
              <i v-else class="far fa-check-circle my-auto text-black-50">미인증</i>
              <span class="font-weight-light">{{ data.foundation }}</span>
            </div>
            <div class="d-flex">
              <span class="mr-auto">부서명</span>
              <span class="font-weight-light">{{ data.department }}</span>
            </div>
            <div class="d-flex">
              <span class="mr-auto">직위</span>
              <span class="font-weight-light">{{ data.position }}</span>
            </div>
            <div class="d-flex">
              <span class="mr-auto">사무실 번호</span>
              <span class="font-weight-light">{{ data.officePhone }}</span>
            </div>
            <div class="d-flex border-0 my-3">
              <div class="text-muted clickable my-auto ml-auto font-weight-light" @click="goPage('Leave')">
                <small class="border-bottom">탈퇴신청하기</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyPage",
  data() {
    return {
      data: {},
    }
  },
  methods: {
    goPage(page) {
      this.$router.push({name:page})
    },
  },
  mounted() {
    this.$post(this.$MYPAGE, null, (result)=>{
      this.data = result['data'][0]
      this.setMyInfo(this.data)
    },(result)=>{
      this.notify('danger', result['message'])
    })
  }
}
</script>

<style scoped>

</style>
