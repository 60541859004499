<template>
  <div class="row sub-menu mb-0 mb-sm-3">
    <div class="container">
      <div class="row d-flex">
        <a v-bind:class="{ active:($route.name.startsWith('Intro')) }" v-bind:href="goLink('Intro')">기관소개</a>
        <a v-bind:class="{ active:($route.name.startsWith('Notice')) }" v-bind:href="goLink('NoticeList')">공지사항</a>
        <a v-bind:class="{ active:($route.name.startsWith('Information')) }" v-bind:href="goLink('InformationList')">이용안내</a>
        <a v-bind:class="{ active:($route.name.startsWith('News')) }" v-bind:href="goLink('NewsList')">뉴스</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuideHeader",
  methods: {
    goPage(page) {
      this.$router.push({name: page})
    }
  },
}
</script>

<style scoped>

</style>