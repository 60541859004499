export default Object.freeze  ({
    install(Vue){
        Vue.prototype.$stripHtml = function (_html){
            let tmp = document.createElement("DIV")
            tmp.innerHTML = _html
            let retStr = tmp.textContent || tmp.innerText || ""
            if (retStr.length > 50) return retStr.substr(0, 50)
            return retStr
        }
        Vue.prototype.goPage = function (page,_params=null){
            window.$('#mobile_menu').hide()
            if (_params) {
                this.$router.push({name: page, params:_params})
            } else {
                this.$router.push({name: page})
            }
        }
        Vue.prototype.goLink = function (page,_params=null){
            window.$('#mobile_menu').hide()
            if (_params) {
                return this.$router.resolve({name: page, _params:_params}).href
            } else {
                return this.$router.resolve({name: page}).href
            }
        }
        Vue.prototype.openNewWindow = function (_url) {
            if (_url == null || _url == '' || _url == '#') return

            window.open(_url)
        }
        Vue.prototype.isEmail = function (asValue) {
            let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,4}$/i
            return regExp.test(asValue);
        }
        Vue.prototype.isNumber = function (asValue) {
            let regExp = /^[0-9]*$/
            return regExp.test(asValue);
        }
        Vue.prototype.trans = function (msg) {
            let arr = {
                'title_danger': '실패',
                'title_success': '성공',
                'title_warning': '경고',
                'title_info': '알림',

                'no_permission_edit': '수정 권한이 없습니다.',
                'no_permission_download': '다운로드 권한이 없습니다.',
                'no_url': '잘못된 접속 입니다.',

                'require_login': '로그인 후 이용가능합니다.',
                'require_target': '대상 회원만 이용가능합니다.',
                'require_fullmember': '재단에서 인증된 회원만 이용가능합니다.',
                'require_lecture_apply': '첨부파일 다운로드는 해당 교육을 수강한 회원만 가능합니다.',
                'require_auth_code': '인증코드를 입력해주세요.',
                'require_password': '비밀번호를 입력해주세요.',
                'require_input': '필수 입력 사항입니다.',
                'require_agree': '개인정보 수집, 유의사항에 동의해주세요.',
                'require_etc': '비고를 입력해주세요.',
                'require_id_unique': '아이디 중복체크를 해주세요.',
                'require_register_agree': '회원가입 약관과 개인정보 수집 및 이용에 대한 안내에 모두 동의해주세요.',

                'fail_file_upload': '파일 등록에 실패 했습니다.',
                'fail_file_download': '파일 다운로드에 실패 했습니다.',
                'fail_write_article': '작성에 실패했습니다.',
                'fail_write_article_prefix': '카테고리를 선택해주세요.',
                'fail_write_article_title': '제목을 입력해주세요.',
                'fail_write_article_content': '내용을 50자 이상으로 입력해주세요.',
                'fail_edit_article': '수정에 실패했습니다.',
                'fail_delete_article': '삭제에 실패했습니다.',
                'fail_write_reply': '댓글 등록에 실패 했습니다.',
                'fail_write_reply_length':'댓글을 50자 이하로 입력해주 세요.',
                'fail_edit_reply': '댓글 수정에 실패 했습니다.',
                'fail_delete_reply': '댓글 삭제에 실패 했습니다.',
                'fail_password': '비밀번호가 일치하지 않습니다.',
                'fail_login': '로그인에 실패 했습니다.',
                'fail_lecture_apply': '수강 신청에 실패 했습니다.',
                'fail_network': '네트워크 통신에 실패 했습니다.',
                'fail_email_reg': '이메일 주소를 정확히 입력해주세요.',
                'fail_num_reg': '숫자만 입력가능합니다.',

                'success_logout': '로그아웃 되었습니다.',
                'success_write_article': '작성되었습니다.',
                'success_edit_article': '수정되었습니다.',
                'success_delete_article': '삭제되었습니다.',
                'success_write_reply': '댓글이 등록되었습니다.',
                'success_edit_reply': '댓글이 수정되었습니다.',
                'success_delete_reply': '댓글이 삭제되었습니다.',
                'success_info_change': '변경이 완료되었습니다.',
                'success_send_mail': '메일이 발송 되었습니다.',
                'success_auth': '인증이 완료되었습니다.',
                'success_verify': '인증이 완료되었습니다. 다시 로그인 해주세요.',
                'success_lecture_apply': '수강 신청이 완료되었습니다.',
                'success_use': '사용가능합니다.',

                'confirm_delete_reply': '해당 댓글을 삭제하시겠습니까?',

                'info_member_leave': '탈퇴 관련 안내문을 확인해주세요.',
                'info_retry': '다시 시도해주세요.',

                'latest': '최신순',
            };

            if(arr[msg]) {
                msg = arr[msg]
            }

            return msg
        }
        Vue.prototype.notify = function (type, msg, is_alert=false) {
            msg = this.trans(msg)

            if(is_alert) {
                alert(msg)
            } else {
                this.$notify({'type': type, 'title': this.trans('title_' + type), 'text': msg})
            }
        }

        // Vue.prototype.currentMyInfo = {}
        Vue.prototype.setMyInfo = function (_myinfo) {
            localStorage.setItem('myinfo', JSON.stringify(_myinfo))
        }
        Vue.prototype.getMyInfo = function () {
            return JSON.parse(localStorage.getItem('myinfo'))
        }
        Vue.prototype.storeFlush = function () {
            localStorage.setItem('myinfo', '')
        }
    }
})