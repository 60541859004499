import axios from 'axios';

var requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 60000
    }
}
var requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        timeout: 60000
    }
}
var requestOptionForDownload = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 60000,
        responseType: 'blob',
    }
}
var requestOptionWithTokenForDownload = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        timeout: 60000,
        responseType: 'blob',
    }
}
export default Object.freeze  ({
    install(Vue){
        Vue.prototype.$DOMAIN = process.env.VUE_APP_DOMAIN
        Vue.prototype.$TERMS_CLASS_PRIVACY = "/terms/class/privacy"
        Vue.prototype.$TERMS_CLASS_AGREE = "/terms/class/agree"
        Vue.prototype.$TERMS_CLASS_NOTICE = "/terms/class/notice"
        Vue.prototype.$TERMS_SIGNUP = "/terms/signup"
        Vue.prototype.$TERMS_PRIVACY = "/terms/privacy"
        Vue.prototype.$USER_REQUEST_EMAIL = "/user/request/email"
        Vue.prototype.$USER_VERIFY_EMAIL = "/user/verify/email"
        Vue.prototype.$USER_CHECK_ID = "/user/check/id"
        Vue.prototype.$USER_FOUNDATION_INFO = "/user/foundation/info"
        Vue.prototype.$USER_REGISTER = "/user/register"
        Vue.prototype.$USER_FOUNDATION_VERIFY = "/user/foundation"
        Vue.prototype.$USER_LOGIN = "/user/login"
        Vue.prototype.$USER_LOGOUT = "/user/logout"
        Vue.prototype.$USER_FIND_ID = "/user/find/id"
        Vue.prototype.$USER_FIND_PW = "/user/find/password"
        Vue.prototype.$USER_CHECK_TOKEN = "/user/check/token"
        Vue.prototype.$USER_REFRESH_TOKEN = "/user/refresh/token"
        // Vue.prototype.$MAIN_SEARCH = "/main/search"
        Vue.prototype.$MAIN_VISUAL = "/main/visual"
        Vue.prototype.$MAIN_LECTURE_IMPORTANT = "/main/lecture/important"
        Vue.prototype.$MAIN_LECTURE_INPROGRESS = "/main/lecture/inprogress"
        Vue.prototype.$MAIN_AREA_POLICY = "/main/areapolicy"
        Vue.prototype.$MAIN_NOTICE = "/main/notice"
        Vue.prototype.$MAIN_NEWS = "/main/news"
        Vue.prototype.$MAIN_SUGGESTION = "/main/suggestion"
        Vue.prototype.$MAIN_BANNER = "/main/banner"
        Vue.prototype.$MAIN_POPUP = "/main/popup"
        Vue.prototype.$MAIN_ALL = "/main/all"
        Vue.prototype.$FOOTER = "/main/footer"
        // Vue.prototype.$CURRICULUM_LIST = "/curriculum/list"
        Vue.prototype.$LECTURE_LIST = "/lecture/list"
        Vue.prototype.$LECTURE_SEARCH = "/lecture/search"
        Vue.prototype.$LECTURE_LIST_USER = "/lecture/list/user"
        Vue.prototype.$LECTURE_DETAIL = "/lecture/detail"
        Vue.prototype.$LECTURE_APPLY = "/lecture/apply"
        Vue.prototype.$MYCLASS_LECTURE_LIST = "/myclass/lecture/list"
        Vue.prototype.$MYCLASS_STATUS = "/myclass/status"
        Vue.prototype.$MYCLASS_LECTURE_DETAIL = "/myclass/lecture/detail"
        Vue.prototype.$MYCLASS_LECTURE_UPDATE = "/myclass/lecture/update"
        Vue.prototype.$MYCLASS_LECTURE_REPLY_LIST = "/myclass/lecture/reply/list"
        Vue.prototype.$MYCLASS_LECTURE_REPLY_WRITE = "/myclass/lecture/reply/write"
        Vue.prototype.$MYCLASS_LECTURE_REPLY_EDIT = "/myclass/lecture/reply/edit"
        Vue.prototype.$MYCLASS_LECTURE_REPLY_DELETE = "/myclass/lecture/reply/delete"
        Vue.prototype.$MYCLASS_LECTURE_COMPLETE = "/myclass/lecture/complete"
        Vue.prototype.$MYCLASS_DOCS = "/myclass/docs"
        Vue.prototype.$MYCLASS_DOCS_SEARCH = "/myclass/docs/search"
        Vue.prototype.$MYCLASS_DOCS_DETAIL = "/myclass/docs/detail"
        Vue.prototype.$MYCLASS_COMPLETION_LIST = "/myclass/completion/list"
        Vue.prototype.$MYCLASS_COMPLETION_DETAIL = "/myclass/completion/detail"
        // Vue.prototype.$MYCLASS_CERTIFICATE = "/myclass/certificate"
        // Vue.prototype.$BOARD_SEARCH = "/boards/search"
        Vue.prototype.$BOARD_LIST = "/boards/list"
        Vue.prototype.$BOARD_LIST_FIXED = "/boards/list/fixed"
        Vue.prototype.$BOARD_LIST_PREFIX = "/boards/list/prefix"
        Vue.prototype.$BOARD_PREFIX_LIST = "/boards/prefix/list"
        Vue.prototype.$BOARD_SEARCH = "/boards/search"
        Vue.prototype.$BOARD_DETAIL = "/boards/detail"
        Vue.prototype.$BOARD_DETAIL_USER = "/boards/detail/user"
        Vue.prototype.$BOARD_REPLY_LIST = "/boards/reply/list"
        Vue.prototype.$BOARD_REPLY_LIST_USER = "/boards/reply/list/user"
        Vue.prototype.$BOARD_REPLY_WRITE = "/boards/reply/write"
        Vue.prototype.$BOARD_REPLY_EDIT = "/boards/reply/edit"
        Vue.prototype.$BOARD_REPLY_DELETE = "/boards/reply/delete"
        Vue.prototype.$BOARD_PROPOSAL_WRITE = "/boards/proposal/write"
        Vue.prototype.$BOARD_PROPOSAL_WRITE_FILE_UPLOAD = "/boards/proposal/write/file/upload"
        Vue.prototype.$BOARD_PROPOSAL_FILE_DELETE = "/boards/proposal/file/delete"
        Vue.prototype.$BOARD_PROPOSAL_EDIT = "/boards/proposal/edit"
        Vue.prototype.$BOARD_PROPOSAL_FILE_DOWNLOAD = "/boards/proposal/file/download"
        Vue.prototype.$BOARD_PROPOSAL_FILE_DOWNLOAD_USER = "/boards/proposal/file/download/user"
        Vue.prototype.$BOARD_IMG = "/boards/img/"
        Vue.prototype.$BOARD_PROPOSAL_DELETE = "/boards/proposal/delete"
        Vue.prototype.$INFO_INFO = "/info/info"
        Vue.prototype.$MYPAGE = "/mypage"
        Vue.prototype.$MYPAGE_CHECK_PASSWORD = "/mypage/check/password"
        Vue.prototype.$MYPAGE_EDIT = "/mypage/edit"
        Vue.prototype.$MYPAGE_EDIT_PASSWORD = "/mypage/edit/password"
        Vue.prototype.$MYPAGE_REQUEST_EMAIL = "/mypage/request/email"
        Vue.prototype.$MYPAGE_EDIT_EMAIL = "/mypage/edit/email"
        Vue.prototype.$MYPAGE_LEAVE = "/mypage/leave"

        // api 통신
        Vue.prototype.$post = function (callUrl, postData, success, fail){
            let _requestOption = requestOption()
            let token = localStorage.getItem('token')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }
            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then(response => {
                // console.log(response)
                if (response.data['result'] == true) {
                    success(response.data)
                } else if (response.headers['content-type'] == 'application/octet-stream') {
                    success(response)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                console.log(e)
                let message = ''
                if (e.response.data != null) {
                    if (e.response.data.message.length > 0) {
                        // message = e.response.data.message
                    }
                }

                if (e.response) {
                    if (e.response.status == 400) {
                        console.log('status 400 token error')
                        localStorage.setItem('token','')
                        this.notify('danger', message, true)
                        window.location.reload()
                        return
                    }
                }

                if (message != '') {
                    this.notify('danger', message, true)
                }
                fail(e)
            })
        }
        Vue.prototype.$get = function (callUrl, success, fail){
            axios.get(this.$DOMAIN + callUrl).then(response => {
                if (response.data['result'] == true) {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                fail(e)
                console.log(e)
            })
        }
        Vue.prototype.$download = function (callUrl, postData, success, fail){
            let _requestOption = requestOptionForDownload()
            let token = localStorage.getItem('token');
            if (token && token.length > 0) {
                _requestOption = requestOptionWithTokenForDownload()
            }
            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then(response => {
                console.log(response)
                if (response.headers['content-type'] == 'application/octet-stream') {
                    success(response)
                } else {
                    fail(response.data)
                }
            }).catch(e => {
                fail(e)
            })
        }
        Vue.prototype.$fileDownload = function (response, title){
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
            link.remove()
        }
    }
})
