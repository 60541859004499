import { render, staticRenderFns } from "./ClassDocsView.vue?vue&type=template&id=6fe2fa3b&scoped=true&"
import script from "./ClassDocsView.vue?vue&type=script&lang=js&"
export * from "./ClassDocsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fe2fa3b",
  null
  
)

export default component.exports