<template>
  <div>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>회원 가입을 환영합니다.</h4>
          </div>
          <div class="col-12 board-view pb-4">
            <div class="bg-light mb-3 mt-3 overflow-hidden">
              <div class="row">
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">01.<small> 약관동의</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-active">
                  <h4 class="m-3">02.<small> 본인인증</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">03.<small> 정보입력</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">04.<small> 가입완료</small></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-4">
            <h5>이메일 입력</h5>
            <div class="card">
              <div class="card-body">
                <div class="input-group pb-1">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1">이메일</span>
                  <div class="col-9 d-flex">
                    <input type="text" class="form-control" v-model="requestEmail" :disabled="disableEmail" @keyup.enter="requestCode()">
                    <input type="button" class="btn btn-info clickable ml-3" value="인증요청" @click="requestCode()">
                  </div>
                </div>
                <div class="input-group pb-3">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1"></span>
                  <div class="col-9 ">
                    <p><small class="text-danger">{{ errors['email'] }}</small></p>
                    <p v-show="successEmail"><small class="text-dark">인증 번호가 발송되었습니다. 메일에 포함된 인증번호를 확인해주세요.</small></p>
                  </div>
                </div>
                <div class="input-group pb-1">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1">인증코드</span>
                  <div class="col-9 d-flex">
                    <input type="text" class="form-control" v-model="authCode" @keyup.enter="confirmCode()">
                  </div>
                </div>
                <div class="input-group pb-3"><span class="col-3 text-right my-auto pr-sm-5 pr-1"></span>
                  <div class="col-9 ">
                    <small class="text-danger">{{ errorMessage }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 col-lg-4 col-xl-3 m-auto pb-3">
            <input type="button" class="form-control btn btn-info clickable" value="확인" @click="confirmCode()">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MailAuth",
  props: {
    agree: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      requestEmail: '',
      confirmEmail: '',
      authCode: '',
      isPending:false,
      disableEmail: false,
      successEmail: false,
      errorMessage:'',
      errors:{
        'email':'',
      },
    }
  },
  methods: {
    requestCode() {
      if (this.isPending) {
        return
      }
      if (this.requestEmail == '') {
        this.errors['email'] = this.trans('require_input')
        return
      }
      if (!this.isEmail(this.requestEmail)) {
        this.errors['email'] = this.trans('fail_email_reg')
        return
      }
      this.isPending = true
      this.disableEmail = true
      let formData = new FormData()
      formData.append('email', this.requestEmail)
      this.$post(this.$USER_REQUEST_EMAIL, formData, () => {
        this.isPending = false
        this.confirmEmail = this.requestEmail
        this.successEmail = true
        this.errors['email'] = ''
      }, (result) =>{
        this.isPending = false
        this.disableEmail = false
        this.errors['email'] = result['message']
      })
    },
    confirmCode() {
      if (this.isPending) {
        return
      }
      if (this.authCode == '') {
        this.errorMessage = this.trans('require_auth_code')
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('email', this.confirmEmail)
      formData.append('code', this.authCode)
      this.$post(this.$USER_VERIFY_EMAIL, formData, (result) => {
        this.isPending = false
        if (result['data'][0].length > 0) {
          this.$router.push({name: 'SignUp', params: {mac: result['data'][0], email: this.confirmEmail}})
          this.notify('success', 'success_auth', true)
        } else {
          this.errorMessage = this.trans('info_retry')
        }
      }, (result) => {
        this.isPending = false
        this.errorMessage = result['message']
      })
    }
  },
  mounted() {
    if (!this.$route.params.agree) {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>
