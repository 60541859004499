<template>
  <div>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <h4>내 정보 수정</h4>
        <div class="row board">
          <div class="col-12 pb-4">
            <h3 class="py-3">비밀번호 변경</h3>
            <div class="card">
              <div class="card-body">
                <div class="input-group pb-1">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1">기존 비밀번호</span>
                  <div class="col-9 d-flex">
                    <input type="password" class="form-control" v-model="old_password">
                  </div>
                </div>
                <div class="input-group pb-3">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1"></span>
                  <div class="col-9 ">
                    <small class="text-danger">{{ errors['old'] }}</small>
                  </div>
                </div>
                <div class="input-group pb-3"><span class="col-3 text-right my-auto pr-sm-5 pr-1"></span>
                  <div class="col-9">
                    <small class="text-dark">회원님의 정보를 안전하게 보호하기 위해 비밀번호를 다시 한번 입력해 주세요.</small>
                  </div>
                </div>
                <div class="input-group pb-1">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1">신규 비밀번호</span>
                  <div class="col-9 d-flex">
                    <input type="password" class="form-control" v-model="new_password">
                  </div>
                </div>
                <div class="input-group pb-3">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1"></span>
                  <div class="col-9 ">
                    <small class="text-danger">{{ errors['new'] }}</small>
                  </div>
                </div>
                <div class="input-group pb-1">
                  <span class="col-3 text-right my-auto pr-sm-5 pr-1">비밀번호 확인</span>
                  <div class="col-9 d-flex">
                    <input type="password" class="form-control" v-model="new_password_re" @keyup.enter="editPw()">
                  </div>
                </div>
                <div class="input-group pb-3"><span class="col-3 text-right my-auto pr-sm-5 pr-1"></span>
                  <div class="col-9">
                    <small class="text-danger">{{ errorsMessage }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-6 m-auto pb-3">
            <input type="button" class="form-control btn btn-info clickable" value="비밀번호 변경" @click="editPw()">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditPw",
  data() {
    return {
      old_password: '',
      new_password: '',
      new_password_re: '',
      isPending: false,
      errorsMessage:'',
      errors: {
        'old':'',
        'new':'',
      },
    }
  },
  methods: {
    editPw() {
      if (this.isPending) {
        return
      }
      if (this.old_password == '') {
        this.errors['old'] = this.trans('require_input')
        return
      }
      if (this.new_password == '') {
        this.errors['new'] = this.trans('require_input')
        return
      }
      if (this.new_password_re == '') {
        this.errorsMessage = this.trans('require_input')
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('oldPassword', this.old_password)
      formData.append('password', this.new_password)
      formData.append('passwordConfirm', this.new_password_re)
      this.$post(this.$MYPAGE_EDIT_PASSWORD, formData, () => {
        this.isPending = false
        this.notify('success', 'success_info_change')
        this.$router.back()
      }, (result) => {
        this.isPending = false
        this.errors['old'] = ''
        this.errors['new'] = ''
        this.errorsMessage = result['message']
      })
    }
  }
}
</script>

<style scoped>

</style>
