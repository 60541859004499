<template>
  <div class="row sub-menu mb-0 mb-sm-3">
    <div class="container">
      <div class="row d-flex">
        <a v-bind:class="{ active:($route.name == 'InClassList' || $route.name == 'InClassView' || $route.name == 'LiveClassView') }" v-bind:href="goLink('InClassList')">교육 현황</a>
        <a v-bind:class="{ active:($route.name == 'ClassDocsList' || $route.name == 'ClassDocsView') }" v-bind:href="goLink('ClassDocsList')">교육자료실</a>
        <a v-bind:class="{ active:($route.name == 'ClassHistory') }" v-bind:href="goLink('ClassHistory')">수강이력</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyClassHeader",
  data() {
    return {
    }
  },
  methods: {

  },
  mounted() {
  }
}
</script>

<style>

</style>