<template>
  <div>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4>회원 가입을 환영합니다.</h4>
          </div>
          <div class="col-12 board-view pb-4">
            <div class="bg-light mb-3 mt-3 overflow-hidden">
              <div class="row">
                <div class="d-flex col-sm-6 col-12 col-md-3 step-active">
                  <h4 class="m-3">01.<small> 약관동의</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">02.<small> 본인인증</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">03.<small> 정보입력</small></h4>
                </div>
                <div class="d-flex col-sm-6 col-12 col-md-3 step-none">
                  <h4 class="m-3">04.<small> 가입완료</small></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-4">
            <h5>지역문화재단 역량강화 사이트 회원가입 약관동의</h5>
            <span>지역문화재단 역량강화에 가입하기 위해서는 아래의 회원가입약관 및 개인정보처리방침안내를 읽고 내용에 동의하셔야 합니다.</span>
          </div>
          <div class="col-12 pb-4">
            <h5>회원가입 약관</h5>
            <div class="card">
              <div class="card-body" style="overflow-x: hidden; max-height: 200px;">
                <div class="card-text" v-html="termSignUp"></div>
              </div>
            </div>
            <input type="checkbox" class="mt-auto mb-auto mr-1" v-model="isAgree1"/>
            <small class="form-check-label text-muted mr-auto">회원가입 약관의 내용에 동의합니다.</small>
          </div>
          <div class="col-12 pb-1">
            <h5>개인정보 수집 및 이용 동의</h5>
            <div class="card">
              <div class="card-body" style="overflow-x: hidden; max-height: 200px;">
                <div class="card-text" v-html="termPrivacy"></div>
              </div>
            </div>
            <input type="checkbox" class="mt-auto mb-auto mr-1" v-model="isAgree2"/>
            <small class="form-check-label text-muted mr-auto">개인정보처리방침 안내의 내용에 동의합니다.</small>
          </div>
          <div class="col-12 pb-4">
            <small class="text-danger">{{ errorMessage }}</small>
          </div>
          <div class="col-12 col-md-5 col-lg-4 col-xl-3 m-auto pb-3">
            <input type="button" class="form-control btn btn-info clickable" value="확인" @click="next()">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terms",
  data() {
    return {
      isAgree1: false,
      isAgree2: false,
      termSignUp:'',
      termPrivacy:'',
      errorMessage:'',
    }
  },
  methods: {
    next() {
      if (this.isAgree1 && this.isAgree2) {
        this.$router.push({name: 'MailAuth', params: {agree: 'true'}})
      } else {
        this.errorMessage = this.trans('require_register_agree')
      }
    }
  },
  mounted() {
    this.$get(this.$TERMS_SIGNUP, (result)=>{
      this.termSignUp = result['data'][0]
    },()=>{
    })
    this.$get(this.$TERMS_PRIVACY, (result)=>{
      this.termPrivacy = result['data'][0]
    },()=>{
    })
  }
}
</script>

<style>
</style>
