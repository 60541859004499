<template>
  <div>
    <PolicyHeader></PolicyHeader>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <div class="row">
          <div class="col-12 board-write">
            <select class="form-control mb-2" v-model="selectedPrefix" >
              <option value="" selected>카테고리를 선택하세요</option>
              <option v-for="prefixOption in prefixList" v-bind:key="prefixOption.prefixIdx" v-bind:value="prefixOption.prefixIdx">
                {{ prefixOption.prefixName }}
              </option>
            </select>
            <input type="text" class="form-control mb-2" v-model="title" placeholder="제목을 입력하세요.">
            <editor class="mb-2" height="500px" initialEditType="wysiwyg" ref="toastuiEditor" />
            <div class="row">
              <div class="col-12 col-md-6 mb-2">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">목록 이미지</span>
                  </div>
                  <input v-if="filesHash['thumbnail'] == ''" type="file" class="form-control" @change="onThumbnailChange" />
                  <input v-else type="button" class="form-control text-left" v-bind:value="fileName['thumbnail']" data-toggle="modal" data-target="#thumbnailModal">
                  <div v-if="filesHash['thumbnail'] != ''" class="input-group-append">
                    <button class="btn btn-outline-danger" type="button" @click="removeFile('thumbnail')">삭제</button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mb-2">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">상세 이미지</span>
                  </div>
                  <input v-if="filesHash['contentImg'] == ''" type="file" class="form-control" @change="onContentImgChange" />
                  <input v-else type="button" class="form-control text-left" v-bind:value="fileName['contentImg']" data-toggle="modal" data-target="#contentImgModal">
                  <div v-if="filesHash['contentImg'] != ''" class="input-group-append">
                    <button class="btn btn-outline-danger" type="button" @click="removeFile('contentImg')">삭제</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-sm-4 mb-2">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">작성자</span>
                  </div>
                  <input type="text" class="form-control" v-bind:value="getMyInfo().name" disabled>
                </div>
              </div>
              <div class="col-12 col-sm-8">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">첨부파일 1</span>
                  </div>
                  <input v-if="filesHash['file0'] == ''" type="file" class="form-control" @change="onFile0Change" />
                  <input v-else type="text" class="form-control text-left" v-bind:value="fileName['file0']" disabled>
                  <select class="custom-select" v-model="filePermission['file0']" v-bind:disabled="filesHash['file0'] != '' ? true : false">
                    <option value="0">일반회원 이상</option>
                    <option value="1">인증회원 이상</option>
                  </select>
                  <div v-if="filesHash['file0'] != ''" class="input-group-append">
                    <button class="btn btn-outline-danger" type="button" @click="removeFile('file0')">삭제</button>
                  </div>
                  <div v-else class="input-group-append">
                    <button class="btn btn-outline-info" type="button" @click="upload('file0')">등록</button>
                  </div>
                </div>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">첨부파일 2</span>
                  </div>
                  <input v-if="filesHash['file1'] == ''" type="file" class="form-control" @change="onFile1Change" />
                  <input v-else type="text" class="form-control text-left" v-bind:value="fileName['file1']" disabled>
                  <select class="custom-select" v-model="filePermission['file1']" v-bind:disabled="filesHash['file1'] != '' ? true : false">
                    <option value="0">일반회원 이상</option>
                    <option value="1">인증회원 이상</option>
                  </select>
                  <div v-if="filesHash['file1'] != ''" class="input-group-append">
                    <button class="btn btn-outline-danger" type="button" @click="removeFile('file1')">삭제</button>
                  </div>
                  <div v-else class="input-group-append">
                    <button class="btn btn-outline-info" type="button" @click="upload('file1')">등록</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex mb-3">
              <input type="button" class="btn btn-secondary mr-auto" value="취소" @click="goCancle()"/>
              <input type="button" class="btn btn-success" value="작성완료" @click="write()"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="thumbnailModal" tabindex="-1" aria-labelledby="thumbnailModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="thumbnailModalLabel">목록 이미지 미리보기</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img class="mw-100" :src="$DOMAIN + $BOARD_IMG + filesHash['thumbnail']" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">닫기</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="contentImgModal" tabindex="-1" aria-labelledby="contentImgModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="contentImgModalLabel">상세 이미지 미리보기</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img class="mw-100" :src="$DOMAIN + $BOARD_IMG + filesHash['contentImg']" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PolicyHeader from "@/components/policyinfo/PolicyHeader";
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';

export default {
  name: "ForumWrite",
  components: {
    'PolicyHeader': PolicyHeader,
    'editor': Editor,
  },
  data() {
    return {
      isPending:false,
      isEdit: false, //테스트중
      loaded: false,
      idx:'',
      selectedPrefix: '',
      title:'',
      content: '',
      files:{
        'thumbnail':null,
        'contentImg':null,
        'file0':null,
        'file1':null,
      },
      filesIdx:{
        'thumbnail':'',
        'contentImg':'',
        'file0':'',
        'file1':'',
      },
      filesHash:{
        'thumbnail':'',
        'contentImg':'',
        'file0':'',
        'file1':'',
      },
      filePermission:{
        'file0':'0',
        'file1':'0',
      },
      fileName: {
        'thumbnail':'',
        'contentImg':'',
        'file0':'',
        'file1':'',
      },
      prefixList:[],
      // boardIdx: '4',
    }
  },
  methods: {
    onThumbnailChange(e) {
      this.filesIdx['thumbnail'] = ''
      this.filesHash['thumbnail'] = ''
      let files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return
      let formData = new FormData()
      formData.append('boardIdx', this.$route.params.boardIdx)
      formData.append('file', files[0])
      formData.append('downloadRule', '2')

      this.$post(this.$BOARD_PROPOSAL_WRITE_FILE_UPLOAD, formData, (result)=>{
        this.filesIdx['thumbnail'] = result['data'][0].fileIdx
        this.filesHash['thumbnail'] = result['data'][0].hashPath
        this.fileName['thumbnail'] = files[0].name
      }, ()=>{
        this.notify('danger', 'fail_file_upload')
      })
    },
    onContentImgChange(e) {
      this.filesIdx['contentImg'] = ''
      this.filesHash['contentImg'] = ''
      let files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return
      let formData = new FormData()
      formData.append('boardIdx', this.$route.params.boardIdx)
      formData.append('file', files[0])
      formData.append('downloadRule', '2')
      this.$post(this.$BOARD_PROPOSAL_WRITE_FILE_UPLOAD, formData, (result)=>{
        this.filesIdx['contentImg'] = result['data'][0].fileIdx
        this.filesHash['contentImg'] = result['data'][0].hashPath
        this.fileName['contentImg'] = files[0].name
      }, ()=>{
        this.notify('danger', 'fail_file_upload')
      })
    },
    onFile0Change(e) {
      this.files['file0'] = null
      this.filesIdx['file0'] = ''
      this.filesHash['file0'] = ''
      let files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return
      this.files['file0'] = files[0]
    },
    onFile1Change(e) {
      this.files['file1'] = null
      this.filesIdx['file1'] = ''
      this.filesHash['file1'] = ''
      let files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return
      this.files['file1'] = files[0]
    },
    upload(_fileName) {
      let formData = new FormData()
      formData.append('boardIdx', this.$route.params.boardIdx)
      formData.append('file', this.files[_fileName])
      formData.append('downloadRule', this.filePermission[_fileName])
      this.$post(this.$BOARD_PROPOSAL_WRITE_FILE_UPLOAD, formData, (result)=>{
        this.filesIdx[_fileName] = result['data'][0].fileIdx
        this.filesHash[_fileName] = result['data'][0].hashPath
        this.fileName[_fileName] = this.files[_fileName].name
      }, ()=>{
        this.notify('danger', 'fail_file_upload')
      })
    },
    removeFile(_file) {
      this.files[_file] = null
      if (this.isEdit) {
        this.filesIdx[_file] = '0'
      } else {
        this.filesIdx[_file] = ''
      }
      this.filesHash[_file] = ''
    },
    goCancle() {
      this.$router.back()
    },
    write() {
      if (this.isPending) {
        return
      }
      if (this.selectedPrefix == '') {
        this.notify('warning', 'fail_write_article_prefix')
        return
      }
      if (this.title == '') {
        this.notify('warning', 'fail_write_article_title')
        return
      }
      if (this.$refs.toastuiEditor.invoke("getHtml").length < 50) {
        this.notify('warning', 'fail_write_article_content')
        return
      }
      this.isPending = true
      let formData = new FormData()
      formData.append('boardIdx', this.$route.params.boardIdx)
      formData.append('title', this.title)
      formData.append('article', this.$refs.toastuiEditor.invoke("getHtml"))
      formData.append('prefixIdx', this.selectedPrefix)
      if (this.filesIdx['thumbnail'] != '') {
        formData.append('thumbnail', this.filesIdx['thumbnail'])
      }
      if (this.filesIdx['contentImg'] != '') {
        formData.append('contentImg', this.filesIdx['contentImg'])
      }
      if (this.filesIdx['file0'] != '') {
        formData.append('files', this.filesIdx['file0'])
      }
      if (this.filesIdx['file1'] != '') {
        formData.append('files', this.filesIdx['file1'])
      }

      let url = this.$BOARD_PROPOSAL_WRITE
      let successMessage = 'success_write_article'
      let failMessage = 'fail_write_article'
      if (this.isEdit) {
        url = this.$BOARD_PROPOSAL_EDIT
        formData.append('idx', this.$route.params.idx)
        successMessage = 'success_edit_article'
        failMessage = 'fail_edit_article'
      }
      this.$post(url, formData, ()=>{
        this.isPending = false
        this.notify('success', successMessage)
        this.$router.back()
      }, ()=>{
        this.isPending = false
        this.notify('danger', failMessage)
      })
    },
    getContent() {
      console.log(this.$refs.toastuiEditor.invoke("getHtml"))
    },
    onLoadEditor() {
      if (!this.loaded) {
        this.loaded = true
        if (this.isEdit && this.content != '') {
          this.$refs.toastuiEditor.invoke('setHtml', this.content)
          this.content = ''
        }
      }
    },
    setDataForEdit() {
      this.isEdit = true
      let formData = new FormData()
      formData.append('idx', this.$route.params.idx)
      formData.append('boardIdx', this.$route.params.boardIdx)
      this.$post(this.$BOARD_DETAIL_USER, formData, (result)=>{
        let data = result['data'][0]
        if (data.userIdx != this.getMyInfo().idx) {
          this.notify('warning', 'no_permission_edit')
          this.$router.back()
          return
        }
        this.selectedPrefix = data.prefixIdx
        this.title = data.title
        this.content = data.content
        this.filesIdx['thumbnail'] = '0'
        this.filesIdx['contentImg'] = '0'
        if (data.thumbnail.idx != null) {
          this.filesIdx['thumbnail'] = data.thumbnail.idx
          this.filesHash['thumbnail'] = data.thumbnail.link
          this.fileName['thumbnail'] = data.thumbnail.name
        }
        if (data.contentImg.idx != null) {
          this.filesIdx['contentImg'] = data.contentImg.idx
          this.filesHash['contentImg'] = data.contentImg.link
          this.fileName['contentImg'] = data.contentImg.name
        }
        this.filesIdx['file0'] = '0'
        this.filesIdx['file1'] = '0'
        if (data.attachedFile.length > 0) {
          this.filesIdx['file0'] = data.attachedFile[0].idx
          this.filesHash['file0'] = data.attachedFile[0].link
          this.fileName['file0'] = data.attachedFile[0].name
          if (data.attachedFile.length == 2) {
            this.filesIdx['file1'] = data.attachedFile[1].idx
            this.filesHash['file1'] = data.attachedFile[1].link
            this.fileName['file1'] = data.attachedFile[1].name
          }
        }
        if (this.loaded) {
          this.$refs.toastuiEditor.invoke('setHtml', this.content)
          this.content = ''
        }
      }, ()=>{

      })
    }
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token || token.length == 0) {
      this.notify('warning', 'require_login')
      this.$router.back()
      return
    }
    let formData = new FormData()
    formData.append('boardIdx', this.$route.params.boardIdx)
    this.$post(this.$BOARD_PREFIX_LIST, formData, (result)=>{
      this.prefixList = result['data']
      if (this.$route.params.idx) {
        this.setDataForEdit()
      }
    }, ()=>{

    })
    this.loaded = true
  }
}
</script>

<style scoped>

</style>