<template>
  <div>
    <div class="row sub-menu mb-0">
      <div class="container">
        <div class="row d-flex">
          <a v-bind:class="{ active:($route.name.startsWith('LectureView')) }" v-bind:href="goLink('LectureView')">교육정보</a>
        </div>
      </div>
    </div>
    <div class="row sub-layout mt-0 mt-sm-3">
      <div class="container">
        <div class="row board-table">
          <div class="col-12">
            <div class="row py-3">
              <div class="col-12 col-md-6 mb-3 mb-md-0"><img class="cover w-100" v-bind:src="$DOMAIN + $BOARD_IMG + data.image"/></div>
              <div class="col-12 col-md-6 d-flex flex-column">
                <div class="d-flex flex-column info mb-3">
                  <h4>{{ data.title }}</h4>
                  <div>
                    <span class="badge badge-dark mb-1">{{ data.type == 1 ? '현장 교육' : '온라인 교육' }}</span>
                  </div>
                  <span>
                    <label>교육운영기관</label>{{ data.organization }}
                  </span>
                  <span>
                    <label>강사정보</label>{{ data.lecturer }}
                  </span>
                </div>
                <input type="button" v-if="data.isRegister != true && $ableDate(data.registerOpen, data.registerClose) && $ableDate(0, data.endAt)" class="mt-auto btn btn-info fixed-size" value="수강신청" @click="goApply(data)" />
                <input type="button" v-else-if="data.isRegister == true && $ableDate(data.registerOpen, data.registerClose) && $ableDate(0, data.endAt)" class="mt-auto btn btn-success fixed-size" value="수강중" disabled />
                <input type="button" v-else-if="$ableDate(data.registerOpen, data.registerClose) == false || $ableDate(0, data.endAt) == false" class="mt-auto btn btn-secondary fixed-size" value="수강종료" disabled />
              </div>
            </div>
            <table class="table table-bordered">
              <tbody>
              <tr class="d-none d-md-table-row">
                <td style="word-break: keep-all" width="10%"><strong>교육대상회원</strong></td>
                <td v-if="data.target">{{ data.target=='FULLMEMBER'?'재단회원':'일반회원' }}</td>
                <td v-else>전체</td>
                <td style="word-break: keep-all" width="10%"><strong>교육대상기관</strong></td>
                <td>{{ data.targetAreaName }} {{ data.targetFoundationName }}</td>
              </tr>
              <tr class="d-table-row d-md-none">
                <td style="word-break: keep-all" width="10%"><strong>교육대상회원</strong></td>
                <td colspan="3" v-if="data.target">{{ data.target =='FULLMEMBER'?'재단회원':'일반회원' }}</td>
                <td colspan="3" v-else>전체</td>
              </tr>
              <tr class="d-table-row d-md-none">
                <td><strong>교육대상기관</strong></td>
                <td colspan="3">{{ data.targetAreaName }} {{ data.targetFoundationName }}</td>
              </tr>
              <tr class="d-none d-md-table-row">
                <td><strong>신청기간</strong></td>
                <td>{{ $getDate(data.startAt) }} ~ {{ $getDate(data.endAt) }}</td>
                <td><strong>수강기간</strong></td>
                <td>{{ $getDate(data.registerOpen) }} ~ {{ $getDate(data.registerClose) }}</td>
              </tr>
              <tr class="d-table-row d-md-none">
                <td><strong>신청기간</strong></td>
                <td colspan="3">{{ $getDate(data.startAt) }} ~ {{ $getDate(data.endAt) }}</td>
              </tr>
              <tr class="d-table-row d-md-none">
                <td><strong>수강기간</strong></td>
                <td colspan="3">{{ $getDate(data.registerOpen) }} ~ {{ $getDate(data.registerClose) }}</td>
              </tr>
              <tr class="d-none d-md-table-row">
                <td><strong>강의시간</strong></td>
                <td>{{ data.length }}</td>
                <td><strong>시수</strong></td>
                <td>{{ data.credit }}</td>
              </tr>
              <tr class="d-table-row d-md-none">
                <td><strong>강의시간</strong></td>
                <td colspan="3">{{ data.length }}</td>
              </tr>
              <tr class="d-table-row d-md-none">
                <td><strong>시수</strong></td>
                <td colspan="3">{{ data.credit }}</td>
              </tr>
              <tr>
                <td><strong>과정소개</strong></td>
                <td colspan="3" v-html="data.description"></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 mb-3 mt-3">
            <div class="d-flex justify-content-end menu">
              <button type="button" class="btn btn-success" @click="$router.push({name:'LectureList'})">
                <i class="fas fa-list"></i>
                목록
              </button>
            </div>
          </div>
        </div>
      </div>
      <LectureApply></LectureApply>
    </div>
  </div>
</template>

<script>
import LectureApply from "@/components/lecture/LectureApply";
export default {
  name: "LectureView",
  data() {
    return{
      data:{},

    }
  },
  methods: {
    goApply(_lecture) {
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        if (_lecture.target) {
          if (_lecture.target != this.getMyInfo().userRule) {
            this.notify('warning', 'require_target')
            return
          }
        }
        if (_lecture.targetArea) {
          if (_lecture.targetArea != this.getMyInfo().areaIdx) {
            this.notify('warning', 'require_target')
            return
          }
        }
        if (_lecture.targetFoundation) {
          if (_lecture.targetFoundation != this.getMyInfo().foundationIdx) {
            this.notify('warning', 'require_target')
            return
          }
        }
        this.$root.$emit(this.$APPLY_OPEN, _lecture.lectureIdx)
      } else {
        this.notify('warning', 'require_login')
      }
    },
    getLectureInfo(_lectureIdx) {
      let formData = new FormData()
      formData.append('lectureIdx', _lectureIdx)
      this.$post(this.$LECTURE_DETAIL, formData, (result)=>{
        this.data = result['data'][0]
        if (this.data.targetArea) {
          this.$get(this.$USER_FOUNDATION_INFO, (result)=>{
            let areas = result['data']
            for (let i=0; i< areas.length; i++) {
              let area = areas[i]
              if (area.value == this.data.targetArea) {
                this.data.targetAreaName = area.name
                if (this.data.targetFoundation) {
                  for (let j = 0; j<area.foundations.length;j++) {
                    let foundation = area.foundations[j]
                    if (foundation.idx == this.data.targetFoundation) {
                      this.data.targetFoundationName = foundation.name
                      break
                    }
                  }
                }
                this.$mount()
                break
              }
            }
          },()=>{
          })


        }

      }, ()=>{

      })
    }
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token || token.length == 0) {
      this.notify('warning', 'require_login')
      this.$router.back()
      return
    }

    this.getLectureInfo(this.$route.params.lectureIdx)
    this.$root.$on(this.$APPLY_CLOSE, ()=>{
      this.getLectureInfo(this.$route.params.lectureIdx)
    })
  },
  components: {
    'LectureApply': LectureApply
  }
}
</script>

<style scoped>

</style>
