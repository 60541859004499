<template>
  <div id="app">
<!--    <vue-header></vue-header>-->
    <div id="mobile_menu" class="mobilelayer" style="display: none;">
      <div class="float-left">
        <a v-bind:href="goLink('Main')">
          <img src="/images/logo_mobile_menu.png" alt="전국지역문화재단연합회">
        </a>
      </div>
      <div class="float-right mobilemenu">
        <a href="#" onclick="$('#mobile_menu').slideUp()"><i class="fas fa-times"></i></a>
      </div>
      <div class="d-flex flex-column w-100 justify-content-center align-items-center pt-5 menu">
        <a v-bind:href="goLink('LectureList')" class="mb-4">역량강화교육</a>
        <a v-bind:href="goLink('ForumList')" class="mb-4">지역정책정보</a>
        <a v-bind:href="goLink('InClassList')" class="mb-4">나의 강의실</a>
        <a v-bind:href="goLink('Intro')" class="mb-4">안내</a>
        <div class="mt-4 mb-4 hr"></div>
        <div class="mt-4 submenu">
          <a v-bind:href="goLink('MainSearch')" class="pr-3"><i class="fas fa-search"></i></a>
          <a v-show="isLogin" @click="logoutRequest()" class="pr-3">LOGOUT</a>
          <a v-bind:href="goLink('Login')" v-show="!isLogin" class="pr-3">LOGIN</a>
          |
          <a v-bind:href="goLink('MyPage')" v-show="isLogin" class="pl-3">MYPAGE</a>
          <a v-bind:href="goLink('Terms')" v-show="!isLogin" class="pl-3">JOIN</a>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <!-- 헤더: -->
      <div class="row header">
        <div class="container-xl">
          <!-- 로고: -->
          <div class="float-left">
            <a v-bind:href="goLink('Main')">
              <img src="/images/logo.png" alt="전국지역문화재단연합회" class="d-none d-xl-block">
              <img src="/images/logo_mobile.png" alt="전국지역문화재단연합회" class="d-block d-xl-none">
            </a>
          </div>
          <!-- :로고 -->
          <!-- 서브메뉴: -->
          <div class="float-right d-none d-md-flex align-items-center submenu">
            <a v-bind:href="goLink('MainSearch')" class="pr-xl-3 pr-md-1"><img src="/images/search.png" alt="검색"></a>
            <a href="#" v-show="isLogin" @click="logoutRequest()" class="pr-xl-3 pr-md-1">LOGOUT</a>
            <a v-bind:href="goLink('Login')" v-show="!isLogin" class="pr-xl-3 pr-md-1">LOGIN</a>
            |
            <a v-bind:href="goLink('MyPage')" v-show="isLogin" class="pl-xl-3 pl-md-1">MYPAGE</a>
            <a v-bind:href="goLink('Terms')" v-show="!isLogin" class="pl-xl-3 pl-md-1">JOIN</a>
          </div>
          <!-- :서브메뉴 -->
          <!-- 모바일메뉴: -->
          <div class="float-right d-block d-md-none mobilemenu">
            <a href="#" onclick="$('#mobile_menu').slideDown()"><i class="fas fa-bars"></i></a>
          </div>
          <!-- :서브메뉴 -->
          <!-- 메뉴: -->
          <div class="d-none d-md-flex flex-row justify-content-center align-items-center menu">
            <a v-bind:href="goLink('LectureList')" class="pr-xl-5 pr-md-3">역량강화교육</a>
            <a v-bind:href="goLink('ForumList')" class="pr-xl-5 pr-md-3">지역정책정보</a>
            <a v-bind:href="goLink('InClassList')" class="pr-xl-5 pr-md-3">나의 강의실</a>
            <a v-bind:href="goLink('Intro')">안내</a>
          </div>
          <!-- :메뉴 -->
        </div>
      </div>
      <!-- :헤더 -->
      <!-- 컨텐츠: -->
      <router-view></router-view>
      <!-- :컨텐츠 -->
      <!-- 배너: -->
      <div class="row d-flex flex-row justify-content-center banner mt-5">
        <a class="clickable" v-for="item in banner" v-bind:key="item.idx" @click="openNewWindow(item.outLink)"><img v-bind:src="$DOMAIN + $BOARD_IMG + item.hashPath"></a>
      </div>
      <!-- :배너 -->
      <!-- 푸터: -->
      <div class="row footer">
        <div class="container-xl">
          <div class="row">
            <div class="col-12 text-center d-none d-sm-block">
              <div class="row d-flex">
                <div class="col-4 text-right">
                  <img src="/images/logo.png" alt="전국지역문화재단연합회">
                </div>
                <div class="col-8 text-left mt-3">
                  <label>TEL</label> {{ data.tel }}
                  <label class="ml-4">FAX</label> {{ data.fax }} {{ data.hoursOfUse }}<br>
                  <label>ADDRESS</label> {{ data.address }} ({{ data.zip }})<br>
                  <span>COPYRIGHT {{ data.copyright }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 text-center d-sm-none">
              <img src="/images/logo.png" alt="전국지역문화재단연합회"><br><br>
              <label>TEL</label> {{ data.tel }}
              <label class="ml-4">FAX</label> {{ data.fax }} {{ data.hoursOfUse }}<br>
              <label>ADDRESS</label> {{ data.address }} ({{ data.zip }})<br><br><br>
              <span>COPYRIGHT {{ data.copyright }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- :푸터 -->
    </div>
    <notifications position="bottom right" :duration="5000" :max="5">
      <template slot="body" slot-scope="props">
        <div class="card text-white mr-2 mb-2 clickable vue-alert" v-bind:class="'bg-'+(props.item.type?props.item.type:'success')" @click="props.close">
          <div class="card-header" v-if="props.item.title">{{ props.item.title }}</div>
          <div class="card-body" v-html="props.item.text">
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLogin: false,
      isTest: false,
      data: {},
      banner: [],
    }
  },
  methods: {
    logoutRequest() {
      if (this.isPending) {
        return
      }
      this.notify('success', 'success_logout')
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        this.$post(this.$USER_LOGOUT, null, () => {
          this.logout()
        }, () =>{
          this.logout()
        })
      }

    },
    logout() {
      console.log('logout receive')
      this.isLogin = false
      localStorage.setItem('token','')
      this.storeFlush()
      this.goPage('Main')
    },
    handleLogIn() {
      this.isLogin = true
      this.$post(this.$MYPAGE, null, (result)=>{
        this.setMyInfo(result['data'][0])
      },(result)=>{
        this.notify('danger', result['message'])
      })
    },
    handleLogOut() {
      this.logout()
    },
  },
  mounted() {
    console.log('header mounted')
    let token = localStorage.getItem('token')
    if (!token || token.length == 0) {
      this.isLogin = false
    } else {
      this.isLogin = true
      this.$post(this.$MYPAGE, null, (result) => {
        this.setMyInfo(result['data'][0])
      }, () =>{
        this.logout()
      })
    }
    window.addEventListener(this.$EVENT_LOGIN, this.handleLogIn)
    window.addEventListener(this.$EVENT_LOGOUT, this.handleLogOut)

    this.$get(this.$FOOTER, (result)=>{
      this.data = result['data'][0]
    },()=>{
    })

    this.$get(this.$MAIN_BANNER, (result)=>{
      this.banner = result['data']
    },()=>{
    })
  }
}
</script>

<style>

.vue-notification-template.n-light {
  margin: 10px;
  margin-bottom: 0;
  border-radius: 3px;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 20px;
  color: #ffffff;
  background: #2c8bea;
  border: 1px solid #0953a2;
}
.vue-notification-template.n-light .notification-title {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 10px;
  color: #39c9ff;
}
</style>
