<template>
  <div>
    <GuideHeader></GuideHeader>
    <div class="row sub-layout mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12 pb-3">
            <ul class="nav nav-tabs">
              <li class="nav-item clickable" v-for="prefix in prefixList" v-bind:key="prefix.prefixIdx">
                <a v-bind:class="{ active:(prefix.prefixIdx == prefixIdx) }" class="nav-link" data-toggle="tab" @click="selectPrefix(prefix.prefixIdx)">{{ prefix.prefixName }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row board">
          <div class="col-12 pb-3" v-for="(item, i) in data" v-bind:key="item.idx" @click="openDetail(item.idx, item.isOpen)">
            <div class="card clickable">
              <div class="row card-body">
                <div class="col-10 mr-auto">
                  <h5 class="card-title mb-0"><span class="badge badge-secondary">{{ i + 1 }}</span> {{ item.title }}</h5>
                </div>
                <div class="col text-right">
                  <i v-if="!item.isOpen" class="fa fa-angle-down" aria-hidden="true"></i>
                  <i v-else class="fa fa-angle-up" aria-hidden="true"></i>
                </div>
                <div class="col-12">
                  <div class="card-text pt-3" v-if="item.isOpen" v-html="item.detail.content"></div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="data.length == 0" class="col-12 text-center py-5 my-5">
            <div class="text-muted mb-3">등록된 게시글이 없습니다.</div>
          </div>
          <div class="col-12">
            <div class="menu">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import GuideHeader from "@/components/guide/GuideHeader";

export default {
  name: "InformationList",
  components: {
    'GuideHeader' : GuideHeader,
  },
  data() {
    return {
      prefixList:[],
      boardIdx: '7',//test 3, 원래는 7
      data:[],
      page: 0,
      size: 9999,
      sort: 'articleCreatedAt',
      direction: 'desc',
      prefixIdx: '',
    }
  },
  methods: {
    getList() {
      let formData = new FormData()
      formData.append('boardIdx', this.boardIdx)
      formData.append('page', this.page)
      formData.append('size', this.size)
      formData.append('sort', this.sort)
      formData.append('direction', this.direction)
      formData.append('isPreview', '0') //미리보기off
      formData.append('prefixIdx', this.prefixIdx)
      let url = this.$BOARD_LIST_PREFIX

      this.$post(url, formData, (result)=>{
        this.data = result['data']
      }, ()=>{

      })
    },
    selectPrefix(_prefixIdx) {
      this.prefixIdx = _prefixIdx
      this.getList()
    },
    openDetail(_idx, _isOpen) {
      if (_isOpen) {
        this.closeDetail()
        return
      }
      let formData = new FormData()
      formData.append('idx', _idx)
      formData.append('boardIdx', this.boardIdx)
      let url = this.$BOARD_DETAIL
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        url = this.$BOARD_DETAIL_USER
      }
      this.$post(url, formData, (result)=>{
        let data = result['data'][0]
        for (let i=0;i<this.data.length;i++) {
          if (this.data[i].idx == _idx) {
            console.log('열림')
            this.data[i].isOpen = true
            this.data[i].detail = data
          } else {
            this.data[i].isOpen = false
          }
        }
        this.$mount()
      }, ()=>{

      })
    },
    closeDetail() {
      console.log('닫기')
      for (let i=0;i<this.data.length;i++) {
        this.data[i].isOpen = false
      }
      this.$mount()
    }
  },
  mounted() {
    let formData = new FormData()
    formData.append('boardIdx', this.boardIdx)
    this.$post(this.$BOARD_PREFIX_LIST, formData, (result)=>{
      this.prefixList = result['data']
      this.selectPrefix(this.prefixList[0]['prefixIdx'])
    }, ()=>{

    })
  }
}
</script>

<style scoped>

</style>
