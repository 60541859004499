<template>
  <div>
    <MyClassHeader></MyClassHeader>
    <div class="row sub-layout mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="card border-warning mb-3">
              <div class="card-header text-white bg-warning">
                <h3 class="font-weight-light"><strong>{{ getMyInfo().name }}</strong>님의 학습정보 입니다.</h3>
                총 {{ myClassInfo.total }}개의 신청 교육 중<br/>{{ myClassInfo.completion }}개의 교육을 수료하였습니다.
              </div>
              <div class="card-body">
                <div class="row text-center text-dark">
                  <div class="col-6 col-md-3 mb-3 mb-md-0">
                    <h1>{{ myClassInfo.total }}</h1>
                    <span>총 신청교육</span>
                  </div>
                  <div class="col-6 col-md-3 mb-3 mb-md-0">
                    <h1>{{ myClassInfo.inProgress }}</h1>
                    <span>진행중 교육</span>
                  </div>
                  <div class="col-6 col-md-3">
                    <h1>{{ myClassInfo.completion }}</h1>
                    <span>수료 교육</span>
                  </div>
                  <div class="col-6 col-md-3">
                    <h1>{{ myClassInfo.end }}</h1>
                    <span>종료(미수료)교육</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-end mb-3">
          <span class="mr-2" v-if="$getDateWithTime(myClassInfo.lastUpdate) != ''"><small>마지막 학습일은 {{ $getDateWithTime(myClassInfo.lastUpdate) }} 입니다.</small></span>
          <span class="mr-2" v-else><small>아직 학습한 내역이 없습니다.</small></span>
          <div class="flex-grow-1 flex-sm-grow-0 ml-auto">
            <div class="input-group">
              <input type="text" class="form-control" aria-label="Text input with dropdown button" v-model="searchKeyword" @keyup.enter="getList(0)">
              <div class="input-group-append">
                <button class="btn btn-success" type="button" id="button-addon2" @click="getList(0)">검색</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row board">
          <div class="col-12 mb-3" v-for="lecture in data" v-bind:key="lecture.lectureIdx" v-bind:value="lecture">
            <div class="card clickable">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <img v-bind:src="$DOMAIN + $BOARD_IMG + lecture.image" class="card-img cover-sm" alt="게시물 이미지">
                </div>
                <div class="col-md-8">
                  <div class="card-body ellipsis">
                    <h5 class="card-title">{{ lecture.title }}</h5>
                    <div class="card-text row">
                      <span class="text-muted col-4 font-weight-light">교육 구분</span>
                      <span class="text-muted col-8">{{ lecture.type == 1 ? '현장 교육' : '온라인 교육' }}</span>
                      <span class="text-muted col-4 font-weight-light">수강 기간</span>
                      <span class="text-muted col-8">{{ $getDate(lecture.startAt) }} ~ {{ $getDate(lecture.endAt) }}</span>
                      <span class="text-muted col-4 font-weight-light">강의 시간</span>
                      <span class="text-muted col-8">{{ lecture.length }}</span>
                      <span class="text-muted col-4 font-weight-light">시수</span>
                      <span class="text-muted col-8">{{ lecture.credit }}</span>
                    </div>
                    <div class="mt-auto ml-auto float-right pb-3 pb-md-0">
                      <input type="button" class="ml-2 btn btn-dark" value="강의 수료" v-if="lecture.myStatus == 1"/>
                      <input type="button" class="ml-2 btn btn-warning" value="강의 종료" v-else-if="lecture.myStatus == 2"/>
                      <input type="button" class="ml-2 btn btn-success" value="강의 보기" @click="goDetail(lecture)" v-else-if="lecture.type != 1 && $ableDate(0, lecture.endAt)"/>
                      <input type="button" class="ml-2 btn btn-info" value="자세히 보기" @click="goDetail(lecture)" v-else-if="lecture.type == 1 && $ableDate(0, lecture.endAt)"/>
                      <input type="button" class="ml-2 btn btn-warning" value="강의 종료" v-else-if="$ableDate(0, lecture.endAt) == false"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="data.length == 0" class="col-12 text-center py-5 my-5">
            <div class="text-muted mb-3">수강중인 교육이 없습니다.</div>
          </div>
          <div class="col-12">
            <div class="d-flex mb-3 menu">
              <input type="button" class="btn btn-outline-warning" v-show="ableNext" @click="getNextData()" value="더보기"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyClassHeader from "@/components/myclass/MyClassHeader";

export default {
  name: "InClassList",
  data() {
    return {
      myClassInfo: {
        'completion':0,
        'end':0,
        'inProgress':0,
        'total':0,
      },
      searchKeyword: '',
      data:[],
      page: 0,
      size: 6,
      sort: 'createdAt',
      direction: 'desc',
      ablePrev: false,
      ableNext: false,

    }
  },
  methods: {
    goDetail(_lecture) {
      if (_lecture.type == 0) {
        this.$router.push({name: 'InClassView', params: {lectureIdx: _lecture.lectureIdx}})
      } else if (_lecture.type == 1) {
        this.$router.push({name: 'LectureView', params: {lectureIdx: _lecture.lectureIdx}})
      } else if (_lecture.type == 2) {
        this.$router.push({name: 'LiveClassView', params: {lectureIdx: _lecture.lectureIdx}})
      }
    },
    getList(_page,_new=true) {
      this.page = _page
      let formData = new FormData()
      formData.append('page', this.page)
      formData.append('size', this.size)
      formData.append('sort', this.sort)
      formData.append('direction', this.direction)
      formData.append('keyword', this.searchKeyword)

      this.$post(this.$MYCLASS_LECTURE_LIST, formData, (result)=>{
        if (_new) {
          this.data = result['data']
        } else {
          for (let i=0;i < result['data'].length;i++) {
            this.data.push(result['data'][i])
          }
        }
        if (result['total'] > this.size * (this.page + 1)) {
          this.ableNext = true
        } else {
          this.ableNext = false
        }
        this.ablePrev = (this.page == 0 ? false : true)
      }, ()=>{

      })
    },
    getNextData() {
      this.getList(this.page + 1, false)
    },
    getMyStatus() {
      this.$post(this.$MYCLASS_STATUS, null, (result)=>{
        if (result['data'][0]) {
          this.myClassInfo = result['data'][0]
        }
      }, ()=>{

      })
    }
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token || token.length == 0) {
      this.notify('warning', 'require_login')
      this.$router.back()
      return
    }
    this.getMyStatus()
    this.getList(0)
  },
  components: {
    'MyClassHeader': MyClassHeader,
  },
}
</script>

<style scoped>

</style>
