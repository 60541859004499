<template>
  <div>
    <div class="row sub-layout mt-3">
      <div class="container">
        <div class="row board">
          <div class="col-12 col-lg-6 pb-5 pt-5 m-auto">
            <div class="card">
              <div class="card-body">
                <h2>로그인</h2>
                <div class="input-group pt-3 pb-1">
                  <input type="text" class="form-control" v-model="username" placeholder="아이디">
                </div>
                <div class="input-group pb-1">
                  <input type="password" class="form-control" v-model="password" placeholder="비밀번호" @keyup.enter="goLogIn()">
                </div>
                <div class="input-group pb-3">
                  <small class="text-danger">{{ errorMessage }}</small>
                </div>
                <div class="input-group pb-3">
                  <input type="checkbox" class="mt-auto mb-auto mr-1" v-model="saveId" @change="savedId()"/>
                  <label class="form-check-label text-muted mr-auto">아이디저장</label>
                  <span class="text-muted mr-3 clickable" @click="findId()">아이디/비밀번호 찾기</span>
                  <span class="text-muted clickable" @click="signUp()">회원가입</span>
                </div>
                <div class="input-group">
                  <input type="button" class="form-control btn btn-info clickable" value="로그인" @click="goLogIn()">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      isPending:false,
      username: '',
      password: '',
      saveId: false,
      errorMessage: '',
    }
  },
  methods: {
    savedId() {
      if (this.saveId) {
        localStorage.setItem('savedId','true')
        localStorage.setItem('username',this.username)
      } else {
        localStorage.setItem('savedId','false')
        localStorage.setItem('username','')
      }
    },
    findId() {
      this.$router.push({name:'FindId'})
    },
    findPw() {
      this.$router.push({name:'FindPw'})
    },
    signUp() {
      this.$router.push({name:'Terms'})
    },
    goLogIn() {
      if (this.saveId) {
        localStorage.setItem('username',this.username)
      } else {
        localStorage.setItem('username','')
      }
      if (this.isPending) {
        return
      }
      if (this.username == '' || this.password == '') {
        this.errorMessage = this.trans('require_input')
        return
      }
      this.isPending = true
      localStorage.setItem('token','') //로컬 토큰 삭제 후 로그인
      let formData = new FormData()
      formData.append('username', this.username)
      formData.append('password', this.password)
      this.$post(this.$USER_LOGIN, formData, (result) => {
        this.isPending = false
        if (result['data'][0].length > 0) {
          localStorage.setItem('token',result['data'][0])
          this.$post(this.$MYPAGE, null, (result)=>{
            this.setMyInfo(result['data'][0])
            window.dispatchEvent(new Event(this.$EVENT_LOGIN))
          },(result)=>{
            this.notify('danger', result['message'])
            window.dispatchEvent(new Event(this.$EVENT_LOGIN))
          })
          this.$router.push({name:'Main'})
        } else {
          this.errorMessage = this.trans('fail_login')
        }
      }, (result) =>{
        this.isPending = false
        this.errorMessage = result['message']
      })
    }
  },
  mounted() {
    this.saveId = localStorage.getItem('savedId') == 'true' ? true : false
    this.username = localStorage.getItem('username')
  }
}
</script>

<style scoped>

</style>
