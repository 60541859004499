
export default Object.freeze  ({
    install(Vue) {
        Vue.prototype.$getDateForList = function (timestamp) {
            if (timestamp == null || timestamp == '') {
                return ''
            }
            let date = new Date(timestamp * 1000)
            if (date == null) {
                return ''
            }
            let today = new Date()
            if (date.getFullYear() == today.getFullYear() &&
            date.getMonth() == today.getMonth() &&
            date.getDate() == today.getDate()) {
                return this.$getTime(timestamp)
            } else {
                return this.$getDate(timestamp)
            }
        }
        Vue.prototype.$getDate = function (timestamp){
            if (timestamp == null || timestamp == '') {
                return ''
            }
            let date = new Date(timestamp * 1000)
            if (date == null) {
                return ''
            }
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            let day = date.getDate()
            // month += ''
            // day += ''
            // if (month.length == 1) {
            //     month = '0' + month
            // }
            // if (day.length == 1) {
            //     day = '0' + day
            // }
            return year + '.' + month + '.' + day
        }
        Vue.prototype.$getDate1 = function (timestamp){
            if (timestamp == null || timestamp == '') {
                return ''
            }
            let date = new Date(timestamp * 1000)
            if (date == null) {
                return ''
            }
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            let day = date.getDate()
            // month += ''
            // day += ''
            // if (month.length == 1) {
            //     month = '0' + month
            // }
            // if (day.length == 1) {
            //     day = '0' + day
            // }
            return year + '년 ' + month + '월 ' + day + '일'
        }
        Vue.prototype.$getDateNoYear = function (timestamp){
            if (timestamp == null || timestamp == '') {
                return ''
            }
            let date = new Date(timestamp * 1000)
            if (date == null) {
                return ''
            }
            let month = date.getMonth() + 1
            let day = date.getDate()
            return month + '.' + day
        }
        Vue.prototype.$getTime = function (timestamp){
            if (timestamp == null || timestamp == '') {
                return ''
            }
            let date = new Date(timestamp * 1000)
            if (date == null) {
                return ''
            }
            let hour = date.getHours()
            let min = date.getMinutes()
            let sec = date.getSeconds()
            hour += ''
            min += ''
            sec += ''
            if (hour.length == 1) {
                hour = '0' + hour
            }
            if (min.length == 1) {
                min = '0' + min
            }
            if (sec.length == 1) {
                sec = '0' + sec
            }
            return hour + ':' + min + ':' + sec
        }
        Vue.prototype.$getDateWithTime = function (timestamp){
            if (timestamp == null || timestamp == '') {
                return ''
            }
            let date = new Date(timestamp * 1000)
            if (date == null) {
                return ''
            }
            let year = date.getFullYear()
            let month = date.getMonth() + 1
            let day = date.getDate()

            let hour = date.getHours()
            let min = date.getMinutes()

            hour += ''
            min += ''
            if (hour.length == 1) {
                hour = '0' + hour
            }
            if (min.length == 1) {
                min = '0' + min
            }
            return year + '.' + month + '.' + day + ' ' + hour + ':' + min
        }
        Vue.prototype.$ableDate = function (startTime,endTime){
            let date = new Date().getTime()
            date = date / 1000
            if (date > startTime && date < endTime) {
                return true
            } else {
                return false
            }
        }
    }
})
