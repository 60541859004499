<template>
  <div>
    <div class="row sub-menu mb-0">
      <div class="container">
        <div class="row d-flex">
          <a v-bind:class="{ active:($route.name.startsWith('LectureList')) }" v-bind:href="goLink('LectureList')">교육신청</a>
        </div>
      </div>
    </div>
    <div class="row sub-layout">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex align-items-center py-3">
            <span class="mr-auto"></span>
            <button type="button" class="btn btn-light mr-2" v-bind:class="{ active:(sort == 'createdAt') }" @click="sortByDate()">{{ sortDateStr}}</button>
            <button type="button" class="btn btn-light" v-bind:class="{ active:(sort == 'title') }" @click="sortByTitle()">과정명순</button>
          </div>
        </div>
        <div class="row board board-triple">
          <div class="col-12 col-md-6 col-xl-4 pb-3" v-for="lecture in data" v-bind:key="lecture.lectureIdx" v-bind:value="lecture">
            <div class="card" >
              <div class="row no-gutters">
                <img v-bind:src="$DOMAIN + $BOARD_IMG + lecture.image" class="card-img-top clickable" alt="게시물 이미지" @click="goDetail(lecture.lectureIdx)">
                <div class="card-body ellipsis">
                  <h5 class="card-title clickable" @click="goDetail(lecture.lectureIdx)">{{ lecture.title }}</h5>
                  <div class="d-flex">
                    <div class="card-text d-flex flex-column">
                      <div>
                        <span class="badge badge-dark mb-1">{{ lecture.type == 1 ? '현장 교육' : '온라인 교육' }}</span>
                      </div>
                      <span>
                        <label>신청기간</label> {{ $getDate(lecture.registerOpen) }} ~ {{ $getDate(lecture.registerClose) }}
                      </span>
                      <span>
                        <label>시수</label> {{ lecture.credit }}
                      </span>
                    </div>
                    <div class="ml-auto">
                      <input type="button" v-if="lecture.isRegister != true && $ableDate(lecture.registerOpen, lecture.registerClose) && $ableDate(0, lecture.endAt)" class="btn btn-info h-100 fixed-size" value="수강신청" @click="goApply(lecture)" />
                      <input type="button" v-else-if="lecture.isComplete" class="btn btn-secondary h-100 fixed-size" value="수강종료" disabled />
                      <input type="button" v-else-if="lecture.isRegister == true && $ableDate(lecture.registerOpen, lecture.registerClose) && $ableDate(0, lecture.endAt)" class="btn btn-success h-100 fixed-size" value="수강중" disabled />
                      <input type="button" v-else-if="$ableDate(lecture.registerOpen, lecture.registerClose) == false || $ableDate(0, lecture.endAt) == false" class="btn btn-secondary h-100 fixed-size" value="수강종료" disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="data.length == 0" class="col-12 text-center py-5 my-5">
            <div class="text-muted mb-3">등록된 게시글이 없습니다.</div>
          </div>
          <div class="col-12">
            <div class="d-flex mb-3 justify-content-start menu">
              <input type="button" class="btn btn-outline-warning" v-show="ableNext" @click="getNextData()" value="더보기"/>
            </div>
          </div>
        </div>
      </div>
      <LectureApply></LectureApply>
    </div>
  </div>
</template>

<script>
import LectureApply from "@/components/lecture/LectureApply";
export default {
  name: "LectureList",
  data() {
    return {
      data:[],
      page: 0,
      size: 12,
      sort: 'createdAt',
      direction: 'desc',
      sortDateStr: this.trans('latest')+'↓',
      ablePrev: false,
      ableNext: false,
      selectedLecture: {},
      total: 0,
    }
  },
  methods: {
    sortByDate() {
      if (this.sort == 'createdAt') {
        if (this.direction == 'desc') {
          this.direction = 'asc'
          this.sortDateStr = this.trans('latest')+'↑'
        } else {
          this.direction = 'desc'
          this.sortDateStr = this.trans('latest')+'↓'
        }
      } else {
        this.sort = 'createdAt'
        this.direction = 'desc'
        this.sortDateStr = this.trans('latest')+'↓'
      }
      this.getData(0)

    },
    sortByTitle() {
      if (this.sort == 'title') {
        if (this.direction == 'desc') {
          this.direction = 'asc'
        } else {
          this.direction = 'desc'
        }
      } else {
        this.sort = 'title'
        this.direction = 'asc'
      }
      this.sortDateStr = this.trans('latest')+'↓'
      this.getData(0)

    },
    getNewData() {
      this.getData(0)
    },
    getNextData() {
      this.getData(this.page + 1, false)
    },
    getData(_page,_new=true) {
      this.page = _page
      let formData = new FormData()
      formData.append('page', this.page)
      formData.append('size', this.size)
      formData.append('sort', this.sort)
      formData.append('direction', this.direction)
      let url = this.$LECTURE_LIST
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        url = this.$LECTURE_LIST_USER
      }
      this.$post(url, formData, (result)=>{
        if (_new) {
          this.data = result['data']
        } else {
          for (let i=0;i < result['data'].length;i++) {
            this.data.push(result['data'][i])
          }
        }
        if (result['total'] > this.size * (this.page + 1)) {
          this.ableNext = true
        } else {
          this.ableNext = false
        }
        this.ablePrev = (this.page == 0 ? false : true)

        this.total = result['total']
      }, ()=>{

      })
    },
    goDetail(_lectureIdx) {
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        this.$router.push({name: 'LectureView', params: {lectureIdx: _lectureIdx}})
      } else {
        this.notify('warning', 'require_login')
      }
    },
    goApply(_lecture) {
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        if (_lecture.target) {
          if (_lecture.target != this.getMyInfo().userRule) {
            this.notify('warning', 'require_target')
            return
          }
        }
        if (_lecture.targetArea) {
          if (_lecture.targetArea != this.getMyInfo().areaIdx) {
            this.notify('warning', 'require_target')
            return
          }
        }
        if (_lecture.targetFoundation) {
          if (_lecture.targetFoundation != this.getMyInfo().foundationIdx) {
            this.notify('warning', 'require_target')
            return
          }
        }
        this.$root.$emit(this.$APPLY_OPEN, _lecture.lectureIdx)
      } else {
        this.notify('warning', 'require_login')
      }
    },
  },
  mounted() {
    this.getNewData()
    this.$root.$on(this.$APPLY_CLOSE, ()=>{
      this.getNewData()
    })
  },
  components: {
    'LectureApply': LectureApply
  }
}
</script>

<style>

</style>
