<template>
  <div>
    <PolicyHeader></PolicyHeader>
    <div class="row sub-layout mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="card bg-light mb-3">
              <div class="card-body">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button class="btn btn-outline-dark dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ searchName }}</button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item clickable" @click="selectSearchRange(0)">전체</a>
                      <a class="dropdown-item clickable" v-for="prefixOption in prefixList" v-bind:key="prefixOption.prefixIdx" v-bind:value="prefixOption.prefixIdx" @click="selectSearchRange(prefixOption.prefixIdx)">
                        {{ prefixOption.prefixName }}
                      </a>
                    </div>
                  </div>
                  <div class="input-group-prepend">
                    <button class="btn btn-outline-dark dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ ['제목', '내용', '제목+내용'][searchType] }}</button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#" @click="searchType=0">제목</a>
                      <a class="dropdown-item" href="#" @click="searchType=1">내용</a>
                      <a class="dropdown-item" href="#" @click="searchType=2">제목 + 내용</a>
                    </div>
                  </div>
                  <input type="text" class="form-control" aria-label="Text input with dropdown button" v-model="keyword" @keyup.enter="getList(0)">
                  <div class="input-group-append">
                    <button class="btn btn-success" type="button" id="button-addon2" @click="getList(0)">검색</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex align-items-center pb-3">
            <span class="mr-auto">총 {{ total }}건</span>
            <button type="button" class="btn btn-light mr-2" v-bind:class="{ active:(sort == 'articleCreatedAt') }" @click="sortByDate()">{{ sortDateStr}}</button>
            <button type="button" class="btn btn-light" v-bind:class="{ active:(sort == 'title') }" @click="sortByTitle()">제목순</button>
          </div>
        </div>
        <div class="row board">
          <div class="col-12 col-xl-6 pb-3" v-for="item in data" v-bind:key="item.idx" v-bind:value="item">
            <div class="card clickable" @click="goDetail(item.idx)">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <img v-bind:src="$DOMAIN + $BOARD_IMG + item.thumbnail" class="card-img cover-sm" alt="게시물 이미지">
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title"><span class="badge badge-secondary">{{ item.prefixName }}</span> {{ item.title }}</h5>
                    <p class="card-text">{{ $stripHtml(item.content) }}</p>
                    <p class="card-text"><small class="text-muted">조회 {{ item.views }} | {{ $getDate(item.articleCreatedAt) }}</small></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="data.length == 0" class="col-12 text-center py-5 my-5">
            <div class="text-muted mb-3">등록된 게시글이 없습니다.</div>
          </div>
          <div class="col-12">
            <div class="d-flex mb-3 justify-content-end menu">
              <input type="button" class="btn btn-outline-warning mr-auto" v-show="ableNext" @click="getNextData()" value="더보기"/>
<!--              <input type="button" class="btn btn-success" value="글쓰기" @click="goWrite()" />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PolicyHeader from "@/components/policyinfo/PolicyHeader";
export default {
  name: "ForumList",
  components: {
    'PolicyHeader': PolicyHeader,
  },
  data() {
    return {
      boardIdx: '3',
      searchRange: 0,
      searchType: 0,
      keyword: '',
      total: 0,
      totalPage : 0,
      data:[],
      page: 0,
      size: 6,
      sort: 'articleCreatedAt',
      direction: 'desc',
      sortDateStr: this.trans('latest')+'↓',
      ablePrev: false,
      ableNext: false,
      pages : [],
      prefixList:[],
      searchName: '전체',
    }
  },
  methods: {
    getList(_page,_new=true) {
      this.page = _page
      let formData = new FormData()
      formData.append('boardIdx', this.boardIdx)
      formData.append('page', this.page)
      formData.append('size', this.size)
      formData.append('sort', this.sort)
      formData.append('direction', this.direction)
      formData.append('isPreview', '1') //미리보기on
      let url = this.$BOARD_LIST
      if (this.keyword != '') {
        url = this.$BOARD_SEARCH
        formData.append('keyword', this.keyword)
        formData.append('searchRange', this.searchRange)
        formData.append('searchType', this.searchType)
      }
      this.$post(url, formData, (result)=>{
        if (_new) {
          this.data = result['data']
        } else {
          for (let i=0;i < result['data'].length;i++) {
            this.data.push(result['data'][i])
          }
        }
        if (result['total'] > this.size * (this.page + 1)) {
          this.ableNext = true
        } else {
          this.ableNext = false
        }
        this.ablePrev = (this.page == 0 ? false : true)

        this.total = result['total'] ? result['total'] : 0
        this.totalPage = Math.floor(this.total % this.size == 0 ? this.total / this.size : this.total / this.size + 1)
        this.pages = []
        let startPage = (this.page - 5 > 0 ? this.page - 5 : 0)
        let endPage = (this.page + 5 < this.totalPage ? this.page + 5 : this.totalPage)
        for (let i=startPage;i<endPage;i++) {
          this.pages.push(i)
        }
      }, ()=>{

      })
    },
    sortByDate() {
      if (this.sort == 'articleCreatedAt') {
        if (this.direction == 'desc') {
          this.direction = 'asc'
          this.sortDateStr = this.trans('latest')+'↑'
        } else {
          this.direction = 'desc'
          this.sortDateStr = this.trans('latest')+'↓'
        }
      } else {
        this.sort = 'articleCreatedAt'
        this.direction = 'desc'
        this.sortDateStr = this.trans('latest')+'↓'
      }
      this.getList(0)
    },
    sortByTitle() {
      if (this.sort == 'title') {
        if (this.direction == 'desc') {
          this.direction = 'asc'
        } else {
          this.direction = 'desc'
        }
      } else {
        this.sort = 'title'
        this.direction = 'asc'
      }
      this.sortDateStr = this.trans('latest')+'↓'
      this.getList(0)
    },
    getNextData() {
      this.getList(this.page + 1, false)
    },
    goDetail(_idx) {
      let token = localStorage.getItem('token');
      if (token && token.length > 0) {
        this.$router.push({name: 'ForumView', params: {idx: _idx, boardIdx:this.boardIdx}})
      } else {
        this.notify('warning', 'require_login')
      }
    },
    goWrite() {
      this.$router.push({name: 'ForumWrite', params:{boardIdx:this.boardIdx}})
    },
    selectSearchRange(_searchRange) {
      this.searchRange = _searchRange
      if (_searchRange == 0) {
        this.searchName = '전체'
      } else {
        for (let i=0;i<this.prefixList.length;i++) {
          if (this.prefixList[i].prefixIdx == _searchRange) {
            this.searchName = this.prefixList[i].prefixName
            break
          }
        }
      }
    }
  },
  mounted() {
    this.getList(0)
    let formData = new FormData()
    formData.append('boardIdx', this.boardIdx)
    this.$post(this.$BOARD_PREFIX_LIST, formData, (result)=>{
      this.prefixList = result['data']
    }, ()=>{

    })
  },

}
</script>

<style scoped>

</style>
